import React, { useEffect } from "react";
import asyncComponent from "util/asyncComponent";
import { Route, Switch } from "react-router-dom";

import errorsPages from "./../errorsPages/index";
import PricingCalendar from "./PricingCalendarV2/PricingCalendar";
import EventPricing from "./EventPricing/EventPricing";
import AddEvent from "./EventPricing/components/AddEvent";
import ViewEvent from "./EventPricing/components/ViewEvent";
import EditEvent from "./EventPricing/components/EditEvent";

const Revenue = ({ match, sidebarCollapsed}) => {

  useEffect(() => {
    console.log(sidebarCollapsed);
  }, [sidebarCollapsed]);

  return (
    <Switch>
      <Route path={`${match.url}/reservations`} component={asyncComponent(() => import('./reservationList'))} />
      <Route
        path={`${match.url}/bookingFeed`}
        render={(props) => {
          const Component = asyncComponent(() => import('./bookingFeed'));
          return <Component {...props} sidebarCollapsed={sidebarCollapsed} />;
        }}
      />
      <Route path={`${match.url}/properties`} component={asyncComponent(() => import('./propertyList'))} />
      <Route path={`${match.url}/pricing-calendar`} component={PricingCalendar} />
      <Route exact path={`${match.url}/events-pricing`} component={EventPricing} />
      <Route exact path={`${match.url}/events-pricing/new`} component={AddEvent} />
      <Route exact path={`${match.url}/events-pricing/event`} component={ViewEvent} />
      <Route exact path={`${match.url}/events-pricing/edit`} component={EditEvent} />
      <Route path={`${match.url}/propertiesDetail`} component={asyncComponent(() => import('./propertyDetail'))} />
      <Route component={errorsPages} />
    </Switch>
  );
};

export default Revenue;
