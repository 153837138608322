import React, { useState } from 'react';
import styled from 'styled-components';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import InsightsFilter from './components/InsightsFilter';
import InsightsMetricGraph from './components/InsightsMetrics';
import InsightsPriceRevenue from './components/InsightsPriceRevenue';
import { now } from 'constants/GlobalConstant';
import { useSelector } from 'react-redux';
import InsightsProperties from './components/InsightsProperties';
import ReservationsandBookings from './components/ReservationsandBookings';



const InsightsBody = styled(LayoutBody)`  
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const nowDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

const Insights = () => {
  const [monthYear, setMonthYear] = useState(nowDate);
  const { users } = useSelector(({ users }) => ({users}));

  const {dashboardDetail, dashboardDetailsLoaded} = users;
  
  const currentYear = Object.keys(dashboardDetail).length > 0 && dashboardDetail["currentYear"] ? dashboardDetail["currentYear"] : [];
  const  lastYear = Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastYear"] ? dashboardDetail["lastYear"] : [];
  const lastYearChartOCCADR = Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastYearChartOCCADR"] ? dashboardDetail["lastYearChartOCCADR"] : [];
  const currentYearChartOCCADR = Object.keys(dashboardDetail).length > 0 && dashboardDetail["currentYearChartOCCADR"] ? dashboardDetail["currentYearChartOCCADR"] : [];
  const totalRecordsInCurrentYear = currentYearChartOCCADR.length;

  const currentId = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]["currentId"]: 0;


  const currentTotalRent = currentYearChartOCCADR[0]? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentRent"]?.toFixed(0) : 0;
  const currentTotalAncillaryRevenue = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]["currentAncillary"]?.toFixed(0) : 0;
  const currentOccupancy = dashboardDetail["currentSupplyAndDemandBehavior"]?.length &&  dashboardDetail["currentSupplyAndDemandBehavior"][0] ? dashboardDetail["currentSupplyAndDemandBehavior"][totalRecordsInCurrentYear - 1]?.["currentOccupancy"]?.toFixed(0) : 0;
  const currentAdr = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentADR"]?.toFixed(0) : 0;
  const currentREVPAR = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentREVPAR"]?.toFixed(0) : 0;
  const currentTotalNights = dashboardDetail["availableNights"];
  const currentRev = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentRevenue"]?.toFixed(0) : 0;



  
  let lastYearIdArray = [];
  lastYearChartOCCADR.forEach((records) => {
    lastYearIdArray.push(Number(records.lastId));
  });

  let closest;

  if (lastYearIdArray.length > 0) {
    closest = lastYearIdArray.reduce((a, b) => {
      return Math.abs(b - currentId) < Math.abs(a - currentId) ? b : a;
    });
  }

  const closestDays = lastYearChartOCCADR.filter((record) =>  record.lastId === closest);
  const currentRent = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentRent"].toFixed(0): 0;
  const lastRent = (closestDays[0] && closestDays[0]["lastRent"]) ? closestDays[0]?.["lastRent"].toFixed(0) : 0;
  const lastREVPAR = (closestDays[0] && closestDays[0]["lastREVPAR"]) ? closestDays[0]?.["lastREVPAR"] : 0;


  const currentAncillary = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentAncillary"].toFixed(0): 0;
  const lastYearAncillary = (closestDays[0] && closestDays[0]["lastAncillary"]) ? closestDays[0]?.["lastAncillary"].toFixed(0): 0;
  const lastOccupancy     = (closestDays[0] && closestDays[0]["lastOccupancy"]) ? closestDays[0]?.["lastOccupancy"].toFixed(0) : 0;
  const lastAvgDailyRate  = (closestDays[0] && closestDays[0]["lastADR"]) ? closestDays[0]?.["lastADR"].toFixed(0) : 0;

  const totalRentDiff = Number(currentRent) - Number(lastRent);
  const ancillaryDiff = Number(currentAncillary) - Number(lastYearAncillary);
  const occupancyDiff = currentOccupancy- lastOccupancy;
  const adrDiff = Number(currentAdr) - Number(lastAvgDailyRate);



  let dataDiff = [];

  if (currentYear.length > 0) {
    currentYear.forEach((element, index) => {
      let currentId = element.currentId ? element.currentId : 0;
      if (lastYear.length > 0 && lastYear[index]["monthData"] && lastYear[index]["monthData"].length > 0) {
        let lastYearIdArray = [];

        lastYear[index]["monthData"].forEach((records, index) => {
          lastYearIdArray.push(Number(records.lastId));
        });

        if (lastYearIdArray.length > 0) {
          closest = lastYearIdArray.reduce((a, b) => {
            return Math.abs(b - currentId) < Math.abs(a - currentId) ? b : a;
          });
        }

        let currentADR = element.currentADR ? element.currentADR.toFixed(0) : 0;
        let currentRent = element.currentRent
          ? element.currentRent.toFixed(0)
          : 0;
        let currentOccupancy = element.currentOccupancy
          ? element.currentOccupancy.toFixed(0)
          : 0;
        let currentAncillary = element.currentAncillary
          ? element.currentAncillary.toFixed(0)
          : 0;

        let adrDiff = Number(currentADR);
        let rentDiff = Number(currentRent);
        let ancillaryDiff = Number(currentAncillary);
        let occupancyDiff = Number(currentOccupancy);


        dataDiff[index] = {
          'name': element.name,
          'ADR': adrDiff || 0,
          'Occupancy': occupancyDiff || 0,
          "Ancillary Revenue": ancillaryDiff || 0,
          "Total Rent": rentDiff || 0,
        };
      }
    });
  };


  const valueData = {
    totalRent: {
      current: currentTotalRent,
      diff: totalRentDiff,
    },
    ancillary: {
      current: currentTotalAncillaryRevenue,
      diff: ancillaryDiff,
    },
    occupancy: {
      current: currentOccupancy,
      diff: occupancyDiff,
    },
    adr: {
      current: currentAdr,
      diff: adrDiff,
    }
  };

// insights revenue

let lastSupplyAndDemandCount    = 0;
let currentSupplyAndDemandCount = 0;
let lastSupplyAndDemandOcc      = [];
let currentSupplyAndDemandOcc   = [];
let lastSupplyAndDemandADR      = [];
let currentSupplyAndDemandADR   = [];
let nearestForecastOccupancy = 0;
let nearestForecastADR = 0;
let nearestForecastRent = 0;
let nearestForecastRevenue = 0;
let nearestForecastREVPAR = 0;

if (Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastSupplyAndDemandBehavior"]) {
  dashboardDetail["lastSupplyAndDemandBehavior"]?.forEach((element) => {
    if (element.lastId && element.lastId < 365) {
      // lastSupplyAndDemandUpdatedData[lastSupplyAndDemandCount] = element
      lastSupplyAndDemandOcc[lastSupplyAndDemandCount] = {
        primary: element.lastId,
        secondary: element.lastOccupancy ? element.lastOccupancy : "0",
      };
      lastSupplyAndDemandADR[lastSupplyAndDemandCount] = {
        primary: element.lastId,
        secondary: element.lastADR ? element.lastADR.toFixed(0) : "0",
      };
      lastSupplyAndDemandCount++;
    }
  });

  dashboardDetail["currentSupplyAndDemandBehavior"]?.forEach((element) => {
    if (element.currentId && element.currentId < 365) {
      // currentSupplyAndDemandUpdatedData[currentSupplyAndDemandCount] = element
      currentSupplyAndDemandOcc[currentSupplyAndDemandCount] = {
        primary: element.currentId,
        secondary: element.currentOccupancy ? element.currentOccupancy : "0",
      };
      currentSupplyAndDemandADR[currentSupplyAndDemandCount] = {
        primary: element.currentId,
        secondary: element.currentADR ? element.currentADR : "0",
      };
      currentSupplyAndDemandCount++;
    }
  });

  const totalRecordsInLastYear = lastYearChartOCCADR.length;
  const lastOcc = dashboardDetail["lastSupplyAndDemandBehavior"][totalRecordsInLastYear - 1] ? dashboardDetail["lastSupplyAndDemandBehavior"][totalRecordsInLastYear - 1]["lastOccupancy"].toFixed(0) : 0;
  const lastYearREVPAR = lastYearChartOCCADR[totalRecordsInLastYear - 1] ? lastYearChartOCCADR[totalRecordsInLastYear - 1]["lastREVPAR"].toFixed(0) : 0;

  nearestForecastOccupancy = Number(currentOccupancy) + Number(lastOcc - lastOccupancy);
  nearestForecastREVPAR = Number(currentREVPAR) + Number(lastYearREVPAR - lastREVPAR);
  

  nearestForecastADR = nearestForecastREVPAR / (nearestForecastOccupancy / 100);

  nearestForecastRent = nearestForecastREVPAR * Number(currentTotalNights);
  nearestForecastRevenue = (Number(nearestForecastRent)/Number(currentRent)) * Number(currentRev);

};

const priceRevData = [
  {
    label: "Current Occupancy",
    data: currentSupplyAndDemandOcc,
    secondaryAxisID: "Occupancy",
    color: "#2ECADB",
  },
  {
    label: "Last Occupancy",
    data: lastSupplyAndDemandOcc,
    secondaryAxisID: "Occupancy",
    color: "#9747FF",
  },
  
  {
    label: "Current ADR",
    data: currentSupplyAndDemandADR,
    secondaryAxisID: "ADR",
    color: "#FFC805",
  },
  {
    label: "Last ADR",
    data: lastSupplyAndDemandADR,
    secondaryAxisID: "ADR",
    color: "#C6A1F5",
  },
];

const forecastPriceRev = [{
  label: "ADR Forecast",
  isForecast: true,
  data: [
    {
      primary: 0.1,
      secondary: nearestForecastADR > 0 ? nearestForecastADR : 10,
      radius: 5,
    },
  ],
  secondaryAxisID: "ADR",
  color: "#FFC805"
},
{
  label: "Occupancy Forecast",
  isForecast: true,
  data: [
    {
      primary: 0.1,
      secondary: nearestForecastOccupancy > 0 ? nearestForecastOccupancy : 10,
      radius: 5,
    }
  ],
  secondaryAxisID: "Occupancy",
  color: "#2ECADB",
},
]



//revenue metrics

let lCount = 0, cCount = 0;
let lastRevenue = [];
let currentRevenue = [];
let lastREV = [];
let currentREV = [];
let lastRentChart = [];
let currentRentChart = [];

if (Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastYearChartOCCADR"]) {
  dashboardDetail["lastYearChartOCCADR"].forEach((element) => {
    if (element.lastId && element.lastId < 365) {
      lastRevenue[lCount] = {
        primary: element.lastId,
        secondary: element.lastRevenue ? element.lastRevenue.toFixed(0) : "0",
      };
      lastRentChart[lCount] = {
        primary: element.lastId,
        secondary: element.lastRent ? element.lastRent.toFixed(0) : "0",
      };
      lastREV[lCount] = {
        primary: element.lastId,
        secondary: element.lastREVPAR ? element.lastREVPAR.toFixed(0) : "0",
      };
      lCount++;
    }
  });

  dashboardDetail["currentYearChartOCCADR"].forEach((element) => {
    if (element.currentId && element.currentId < 365) {
      currentRevenue[cCount] = {
        primary: element.currentId,
        secondary: element.currentRevenue
          ? element.currentRevenue.toFixed(0)
          : "0",
      };
      currentRentChart[cCount] = {
        primary: element.currentId,
        secondary: element.currentRent ? element.currentRent.toFixed(0) : "0",
      };
      currentREV[cCount] = {
        primary: element.currentId,
        secondary: element.currentREVPAR
          ? element.currentREVPAR.toFixed(0)
          : "0",
      };
      cCount++;
    }
  });
}


const revenueMetricsData = {
  revenueChart : [
    {
      label: "Current Revenue",
      data: currentRevenue,
      secondaryAxisID: "currentRevenue",
      color: "#2ECADB",
    },
    {
      label: "Last Revenue",
      data: lastRevenue,
      secondaryAxisID: "lastRevenue",
      color: "#C6A1F5",
    },
  ],
  rentChart: [
    {
      label:  "Current Rent",
      data:  currentRentChart,
      secondaryAxisID: "currentRent",
      color: "#2ECADB",
    },
    {
      label: "Last Rent",
      data: lastRentChart,
      secondaryAxisID: "lastRent",
      color: "#C6A1F5",
    },
  ],
  revparChart: [
    {
      label: "Current REVPAR",
      data:  currentREV,
      secondaryAxisID: "currentRevPar",
      color: "#2ECADB",
    },
    {
      label: "Last REVPAR",
      data: lastREV,
      secondaryAxisID: "lastRevPar",
      color: "#C6A1F5"
    },
  ]
};



//add forecast 
if (monthYear >= nowDate) {
  priceRevData.push(...forecastPriceRev)
  revenueMetricsData.revenueChart.push({
    label: "Forecast",
    isForecast: true,
    data: [
      {
        primary: 0.1,
        secondary: nearestForecastRevenue,
        radius: 5,
      },
    ],
    secondaryAxisID: "currentRevenue",
    color: "#2ECADB",
  },)
  revenueMetricsData.rentChart.push({
    label: "Forecast",
    isForecast: true,
    data: [
      {
        primary: 0.1,
        secondary: nearestForecastRent,
        radius: 5,
      },
    ],
    secondaryAxisID: "currentRent",
    color: "#2ECADB",
  },)
  revenueMetricsData.revparChart.push({
    label: "Forecast",
    isForecast: true,
    data: [
      {
        primary: 0.1,
        secondary: nearestForecastREVPAR,
        radius: 5,
      },
    ],
    secondaryAxisID: "currentRevPar",
    color: "#2ECADB"
  },)
};


// insights properties data
const InsightsPropsData = {
  inventory:  dashboardDetail["inventoryGrowth"],
  bookings: String(dashboardDetail["totalReservations"]).replace(/\B(?=(\d{3})+(?!\d))/g,","),
  activeProp: dashboardDetail["activeProperties"],
  bookedNights: String(dashboardDetail["bookedNights"]).replace(/\B(?=(\d{3})+(?!\d))/g,",")
}

  return (
    <InsightsBody>
      <InsightsFilter monthYear={monthYear} setMonthYear={setMonthYear}/>
      <InsightsMetricGraph chartData={dataDiff} valueData={valueData} isLoading={!dashboardDetailsLoaded}/>
      <InsightsPriceRevenue priceRevData={priceRevData} revenueMetricsData={revenueMetricsData} isLoading={!dashboardDetailsLoaded} monthYear={monthYear}/>
      <InsightsProperties  InsightsPropsData={InsightsPropsData} isLoading={!dashboardDetailsLoaded}/>
      <ReservationsandBookings pieData={dashboardDetail["reservationPerSource"]} recentBookingData={dashboardDetail["recentActivity"]} isLoading={!dashboardDetailsLoaded}/>
    </InsightsBody>
  )
}


export default Insights;