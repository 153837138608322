import React from 'react'
import styled from 'styled-components'
// import RuralTransit from '../../../../assets/images/ruralTransit.png'
// import Ashura from '../../../../assets/images/ashura.png'
// import PioneerDay from '../../../../assets/images/pioneerDay.png'
import { useSelector } from 'react-redux'
import moment from 'moment'


const Wrapper = styled.div`
  width: 100%;
`;

const UpcomingEvents = styled.h1`
  margin-top: 12px;
  font-size: 16px;
  font-weight: 300;
`;

const EventsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

const OverallDiv = styled.div`
  padding: 16px;
  background-color: white;
  display: inline-block;
  align-items:center;
  min-width: 31rem;
  width: 33%;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const ImageFrame = styled.div`
  padding: 8px;
  ${({theme}) =>  `border: 1px solid ${theme.disabledGrey}`};
`;

const ImageAndTextDiv = styled.div`
   display: flex;
   align-items: center;
   gap: 20px;
`;

const ImageTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageTextTitle = styled.p`
   font-size: 24px;
   line-height: 10px;
   font-weight: 300;
`;

const ImageTextMarket = styled.p`
   font-size: 16px;
   line-height: 10px;
   font-weight: 300;

`;

const ImageTextDateRange = styled.p`
   font-size: 16px;
   line-height: 10px;
   font-weight: 300;
`;
const Events = () => {

  const { eventsTable } = useSelector(({ events }) => events);

  const upcomingEvents = eventsTable?.slice(0, 3) || [];

  const formatDateString = (date) => {
    return moment.utc(date).format('MM/DD/YYYY');
  };

  return (
    <Wrapper>
      <UpcomingEvents>Upcoming Events and Holidays</UpcomingEvents>
      <EventsDiv>
        {upcomingEvents.map((event) => (
          <OverallDiv key={event._id}>
            <ImageAndTextDiv>
              <ImageFrame>
                <img src={ event.imageUrl || "/assets/images/events_placeholder.png"  } alt='events-placeholder' width="112px" height="79px" />
              </ImageFrame>


              <ImageTextDiv>
                <ImageTextTitle>{event.name}</ImageTextTitle>
                <ImageTextMarket>
                  {
                    event.markets && event.markets.length > 0 ? (
                      <div>
                        {event.markets.slice(0, 3).map((market, index) => (
                          <span key={market}>
                            {index > 0 && ', '}
                            {market}
                          </span>
                        ))}
                        {event.markets.length > 2 && <span>...</span>}
                      </div>
                    ) : (
                      <span>No markets available</span>
                    )
                  }
                </ImageTextMarket>
                <ImageTextDateRange>Date range: {formatDateString(event.startDate)} - {formatDateString(event.endDate)}</ImageTextDateRange>
              </ImageTextDiv>

            </ImageAndTextDiv>
          </OverallDiv>
        ))}
      </EventsDiv>

    </Wrapper>
  )
}

export default Events
