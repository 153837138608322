import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Chart } from 'react-charts';

import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle, BodyText } from 'components/ReusableComponents/Text/Text';
import SkLoaderOver from 'components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';



const RevenueMetricDiv = styled.div`
  ${BodyCSS}
  width: 890px;
  min-width: 890px;
  height: 100%;
  padding: ${({$padding}) => $padding};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TitleDiv = styled.div`
  width: 100%;
  padding: 0px 42px;
`;

const BlackText = styled(BodyText)`
  margin: 0px;
  color: black;
`;

const GridTextBottom = styled(BodyText)`
  position: absolute;
  font-size: 14px;
  bottom: 16px;
  margin: auto;
`;

const GridTextLeft = styled(BodyText)`
  position: absolute;
  font-size: 14px;
  transform: rotate(-90deg);
  top: 300px;
  left: ${({$sel}) => $sel};
  margin: auto;
`;

const Legend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 32px 0 0 0;
  padding: 0px 42px;
  position: relative;
`;

const LegendLabel = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LabelColor = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 8px;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: ${({$background}) => $background};
`;

const ButtonDiv = styled.div`
  width: fit-content;
  height: 34px;
  display: flex;
  position: absolute;
  top:0px;
  right: 32px;
`;

const SelButton = css`
  width: fit-content;
  height: 100%;
  border: 1px solid #1BB3CD;
  text-align: center;
  background: none;
  color: #1BB3CD;
  font-family: Commissioner;
  font-size: 16px;
  cursor: pointer;
  box-shadow: none;
  font-weight: 300;
  border-radius:${({$bRadius}) => $bRadius};

  &:hover, &.active, &:focus {
    background: #1BB3CD;
    color: white;
  }
`;

const ChartDiv = styled.div`
  width: 800px;
  height: 380px;
  padding: 32px 0;
  box-sizing: border-box;
`;


//   /** Series for lines to set forcast value */
//   const series =(s, i) => ({
//     type: "line",
//     showPoints: false,
//     precision: 0,
//     showGrid:true,
// });

const RevenueMetrics = ({revenueMetricsData, isLoading, monthYear}) => {

  const [chartSel, setChartSel] = useState("revenue");
  const currencySymbol = localStorage.getItem('currency')

  const onSelButtonClick = (value) => {
    setChartSel(value);
  };

  const chartData = {
    revenue: {
      data: revenueMetricsData.revenueChart,
      axes: [
        {
          primary: true,
          type: 'linear',
          id: 'lastRevenue',
          position: 'bottom',
          hardMax: 365,
          showGrid: true,
          format: d => `Day ${d}`
          
        },
        {
          type: 'linear',
          id: 'currentRevenue',
          position: 'left',
          hardMax: Math.ceil(Math.max(...revenueMetricsData.revenueChart.map(item => Math.max(...item.data.map(o => o.secondary))))),
          hardMin: 0,
          showGrid: true,
          format: d => `${currencySymbol}${d}`
        },
        {
          type: 'linear',
          id: 'xxx',
          position: 'right',
          showGrid: true,
          format: d => ``
        }
      ]
    },
    rent: {
      data: revenueMetricsData.rentChart,
      axes: [
        {
          primary: true,
          type: 'linear',
          id: 'lastRent',
          position: 'bottom',
          hardMax: 365,
          showGrid: true,
          format: d => `Day ${d}`
        },
        {
          type: 'linear',
          id: 'currentRent',
          position: 'left',
          hardMax: Math.ceil(Math.max(...revenueMetricsData.rentChart.map(item => Math.max(...item.data.map(o => o.secondary))))),
          hardMin: 0,
          showGrid: true,
          format: d => `${currencySymbol}${d}`
        },
        {
          type: 'linear',
          id: 'xxx',
          position: 'right',
          showGrid: true,
          format: d => ``
        }
      ]
    },
    revpar: {
      data: revenueMetricsData.revparChart,
      axes: [
        {
          primary: true,
          type: 'linear',
          id: 'lastRevPar',
          position: 'bottom',
          hardMax: 365,
          hardMin: 0,
          showGrid: true,
          format: d => `Day ${d}`
        },
        {
          type: 'linear',
          id: 'currentRevPar',
          position: 'left',
          hardMax: Math.ceil(Math.max(...revenueMetricsData.revparChart.map(item => Math.max(...item.data.map(o => o.secondary))))),
          hardMin: 0,
          showGrid: true,
          format: d => `${currencySymbol}${d}`
        },
        {
          type: 'linear',
          id: 'xxx',
          position: 'right',
          showGrid: true,
          format: d => ``
        }
      ]
    }
  }

  const textSel = {
    revenue: "Revenue",
    rent: "Rent",
    revpar: "RevPAR"
  }

  const series = (s) => ({
    type: s?.originalSeries?.isForecast ? 'bubble' : 'line',
    showPoints: false,
    precision: 0,
    showGrid: true,
});

  const currentYear = monthYear.getFullYear();

  return (
    <RevenueMetricDiv $padding={isLoading ? '16px 32px' : '16px 0px'}>
     <GridTextBottom>
        Days to End of Month      
      </GridTextBottom>
      <GridTextLeft $sel={chartSel === 'rent' ? '8px' : '-4px'}>
        {textSel[chartSel]}
      </GridTextLeft>
      <TitleDiv>
        <BodyTitle>
          Revenue Metrics
        </BodyTitle>
      </TitleDiv>
      <Legend>
        <LegendLabel>
          <BlackText>
            Period Comparison
          </BlackText>
          <LabelColor>
            <Circle $background="#C6A1F5"/>
            <BodyText margin="0 8px 0 8px">
              {currentYear - 1}
            </BodyText>
            <Circle $background="#2ECADB"/>
            <BodyText margin="0 0 0 8px">
              {currentYear}
            </BodyText>
          </LabelColor>
        </LegendLabel>
        <ButtonDiv>
          <ButtonWithFunction className={chartSel==='revenue' &&  'active' } value="revenue" ButtonStyle={SelButton} label={"Revenue"} $bRadius="4px 0 0 4px" onClickFunc={onSelButtonClick}/>
          <ButtonWithFunction className={chartSel==='rent' && 'active' } value="rent" ButtonStyle={SelButton} label={"Rent"} $bRadius="0 0 0 0" onClickFunc={onSelButtonClick}/>
          <ButtonWithFunction className={chartSel==='revpar'  && 'active'} value="revpar" ButtonStyle={SelButton} label={"RevPAR"}  $bRadius="0px 4px 4px 0px" onClickFunc={onSelButtonClick}/>
        </ButtonDiv>
      </Legend>
      {!isLoading ?<ChartDiv>
        {chartSel === 'revenue' ? <Chart data={chartData[chartSel]?.data} series={series} axes={chartData[chartSel]?.axes} grouping="single" tooltip={{ align: "bottom" }}/> : null}
        {chartSel === 'rent' ? <Chart data={chartData[chartSel]?.data} series={series} axes={chartData[chartSel]?.axes} grouping="single" tooltip={{ align: "bottom" }}/> : null}
        {chartSel === 'revpar' ? <Chart data={chartData[chartSel]?.data} series={series} axes={chartData[chartSel]?.axes} grouping="single" tooltip={{ align: "bottom" }}/> : null}
      </ChartDiv> : null}
      <SkLoaderOver isLoading={isLoading} type='card' />
    </RevenueMetricDiv>
  )
}

export default RevenueMetrics;