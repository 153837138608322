import React from "react";
import PropertyMetrics from "../../sharedComponents/MarketPropertyMetrics/PropertyMetrics";
import { useSelector } from "react-redux";
import { DescriptionBody } from "../../sharedComponents/CommonCSS";
import PropertyDetailInfo from "./subComponents/PropertyDescription/PropertyDetailInfo";

const PropertyDescription = ({propertyDetails, monthYear, isFetchingPropertyDetails, margin}) => {

  const { isFetchingPropertyOverviewMetrics, propertyOverviewMetrics } = useSelector(({ property }) => property);
  const {metricYearly, forecastYearly, goalPlanYear} = propertyOverviewMetrics || {};

  return (
    <DescriptionBody margin={margin}>
      <PropertyDetailInfo propertyId={propertyDetails?.id || ''} propertyDetails={propertyDetails} monthYear={monthYear} isFetchingPropertyDetails={isFetchingPropertyDetails}/>
      <PropertyMetrics monthYear={monthYear} forecast={forecastYearly} metrics={metricYearly} goal={goalPlanYear} isFetchingMetrics={isFetchingPropertyOverviewMetrics}  />
    </DescriptionBody>
  )
}

export default PropertyDescription;
