import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import skyscraper from 'assets/images/skyscraper.png'
import quibbleLogo from 'assets/images/logos/quibble-logo.svg'

import CreateAccount from './Signup/components/CreateAccount';
import Verification from './Signup/components/Verification';
import FinishSignup from './Signup/components/FinishSignup';
import { lightScrollbar, darkScrollbar } from "components/ReusableComponents/Scrollbar/Scrollbar";
import PMS from './Signup/components/PMS';
import SignIn from './SignIn/SignIn';

const AuthLayout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const AuthBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;

  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

  @media (max-width: 1920px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const FormBody = styled.div`
  min-width: 960px;
  min-height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 136px 0 0 0;
`;

const BgBody = styled(FormBody)`
  object-fit: cover;
  background-image: url(${skyscraper});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  image-rendering: -webkit-optimize-contrast;
  padding: 64px 0 0 0;
`;

const ImageLabel = styled.span`
  font-size: 36px;
  font-weight: 300;
  color: white;
  font-family: Montserrat;
`;

const ImageLabelBold = styled(ImageLabel)`
  font-size: 54px;
  font-weight: 600;
`;

const Auth = () => {

  const {authUser} = useSelector(({auth}) => auth);


  const [authMenu, setAuthMenu] = useState('create');
  const [createToken, setCreateToken] = useState('') ;
  const [signupString, setSignupString] = useState('');

  const history = useHistory();
  const location = useLocation();
  const {pathname} = location;

  const isSignIn = pathname === '/signin';

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && authUser) {
      history.push('/');
    };
  },[authUser, history]);


  const setAuthMenuFunc = (value) => {
    setAuthMenu(value);
  };


  return (
    <AuthLayout>
      <AuthBody>
        <FormBody>
          <img src={quibbleLogo} alt="Quibble logo" width="215px" height="96px"  />
          {!isSignIn && authMenu === 'create' ? <CreateAccount setAuthMenuFunc={setAuthMenuFunc} setCreateToken={setCreateToken} /> : null}
          {!isSignIn && authMenu === 'verify' ? <Verification  setAuthMenuFunc={setAuthMenuFunc} createToken={createToken} setSignupString={setSignupString}/> : null}
          {!isSignIn && authMenu === 'PMS' ? <PMS signupString={signupString} setAuthMenuFunc={setAuthMenuFunc}/> : null}
          {!isSignIn && authMenu === 'finish' ? <FinishSignup /> : null}
          {isSignIn && <SignIn />}
        </FormBody>
        <BgBody>
          <ImageLabel>
            Take the guesswork out of pricing decisions
          </ImageLabel>
          <ImageLabelBold>
            Increase your revenue by 30%.
          </ImageLabelBold>
        </BgBody>
      </AuthBody>
    </AuthLayout>
  )
}
export default Auth;