import React from 'react';
import { Modal, Table, Input, Radio, Button } from 'antd';

const TagModal = ({ isVisible, onCancel, listings, onApply, tags, parentId, onTagChange, onParentChange }) => {
  
  const handleTagChange = (index, value) => {
    const newTags = [...tags];
    newTags[index] = value;
    onTagChange(newTags);
  };

  const handleParentChange = (id) => {
    onParentChange(id); 
  };

  const handleApply = () => {
    onApply();
  };

  return (
    <Modal
      title="Add Tag"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Table dataSource={listings} rowKey="id" pagination={false}>
        <Table.Column title="Property" dataIndex="name" key="name" />
        <Table.Column title="Market" dataIndex="market" key="market" />
        <Table.Column
          title="Tag"
          key="tag"
          render={(text, record, index) => (
            <Input
              value={tags[index]}
              onChange={(e) => handleTagChange(index, e.target.value)}
            />
          )}
        />
        <Table.Column
          title="Set Parent"
          key="setParent"
          align="center"
          render={(text, record) => {
            // Add a console log to inspect the record
            console.log('Rendering record:', record);

            // Defensive check
            if (!record || !record.id) {
              return null; // or return some default content
            }

            return (
              <Radio
                checked={parentId === record.id}
                onChange={() => handleParentChange(record.id)}
              />
            );
          }}
        />
      </Table>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button type="primary" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default TagModal