/*** Default Import */
import { useEffect } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { getLoginUser, signOutUser } from "../appRedux/actions";

/*** Custom Import */
import Main from "./main/index";
import Revenue from "./revenue/index";
import Extensions from "./extensions/index";
import errorsPages from "./errorsPages/index";
import userProfile from "./userProfile/index";
import userMangment from "./userMangment/index";
import BusinessTools from "./bussinessTools/index";
import marketAnalytics from "./marketAnalytics/index";
import PriceAdjustment from "./priceAdjustment";
// import Listings from "./listings/Listings";
import WorkingQueue from './workingqueue';

import Home from "./home";
import settings from "./settings";
import insights from "./insights";

const App = ({ match, sidebarCollapsed, toggleCollapseNav, onToggleShowFooter }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    let userId = localStorage.getItem("user_id");
    let token = localStorage.getItem("token");
    let values = {
      userId: userId,
      token: token,
    };

    // If not authenticated
    if (!token || token?.trim() === '' || !userId || userId === 'undefined') {
      dispatch(signOutUser());
      return;
    }

    // Top-level call of logged-in user details
    dispatch(getLoginUser(values));
  }, [dispatch, history]);

  // Scroll to top on mount
  useEffect(() => {
    document.querySelector('.gx-main-content-wrapper')?.scrollIntoView();
  }, [pathname])

  return (
    <Switch>
      <Route path={`${match.url}home`} render={(props) => <Home {...props} sidebarCollapsed={sidebarCollapsed} />}/>
      <Route path={`${match.url}main`} component={Main} />
      <Route path={`${match.url}revenue`} render={(props) => <Revenue {...props} sidebarCollapsed={sidebarCollapsed} />}/>
      <Route path={`${match.url}setting`} component={userProfile} />
      <Route path={`${match.url}settings`} component={settings} />
      <Route path={`${match.url}user`} component={userMangment} />
      <Route path={`${match.url}extensions`} component={Extensions} />
      <Route path={`${match.url}market-analytics`} component={marketAnalytics} />
      <Route path={`${match.url}price-adjustment`} component={PriceAdjustment} />
      <Route path={`${match.url}workingqueue`} component={WorkingQueue} />
      <Route path={`${match.url}insights`} component={insights} />
      <Route path={`${match.url}businessTools`} render={(props) => <BusinessTools {...props} sidebarCollapsed={sidebarCollapsed} toggleCollapseNav={toggleCollapseNav} onToggleShowFooter={onToggleShowFooter} />} />
      <Route exact component={errorsPages} />
    </Switch>
  )
};

export default App;
