import React, { useState, useCallback } from 'react';

import styled from 'styled-components';

import { Button, Radio, Space, InputNumber, Slider, Checkbox } from 'antd';

import rating from 'assets/images/markethunter/ratings.svg';

const FilterContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #E8E8E8;
  background: #FFF;
  overflow-y: auto;
  max-height: calc(100vh - 150px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 16px;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  text-transform: capitalize;

  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const FilterGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;

  .ant-input-number {
    margin: 0;
  }
`;

const FilterTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;

  margin-bottom: 8px;
`;



const AmenitiesCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 2px 0;
  }
`;

const RatingsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatingsCheckBoxContainer = styled.div`
  display: flex;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
`;

const BedroomButtonContainer = styled.div`
  display: flex;

  .ant-btn {
    display: flex;
    height: 30px;
    width: 50px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0;
  }

  .ant-btn:not(:first-child) {
    width: 30px;
  }

  .ant-btn:not(:last-child) {
    margin-right: 5px;
  }
`;

const CompetitorReplacementFilter = ({
  viewType,
  typeOfPlace,
  setTypeOfPlace,
  minPrice,
  maxPrice,
  setMinPrice,
  setMaxPrice,
  minStay,
  setMinStay,
  selectedBedrooms,
  setSelectedBedrooms,
  selectedBeds,
  setSelectedBeds,
  selectedRatings,
  setSelectedRatings,
  selectedBathrooms,
  setSelectedBathrooms,
  // selectedAmenities,
  setSelectedAmenities,
  clearAllFilters
}) => {
  
  const [typeOfProperty, setTypeOfProperty] = useState('house');

  const onTypeOfPlaceChange = useCallback((e) => {
    setTypeOfPlace(e.target.value);
  }, [setTypeOfPlace]);
  
  const onTypeOfPropertyChange = (e) => {
    setTypeOfProperty(e.target.value);
  };

  const onMinChange = useCallback((value) => {
    setMinPrice(value);
  }, [setMinPrice]);

  const onMaxChange = useCallback((value) => {
    setMaxPrice(value);
  }, [setMaxPrice]);

  const onMinStayChange = useCallback((value) => {
    setMinStay(value);
  }, [setMinStay]);

  const onRatingChange = useCallback((min, max) => {
    const isSelected = selectedRatings.some(
      (range) => range.min === min && range.max === max
    );
  
    if (isSelected) {
      setSelectedRatings((prevRanges) =>
        prevRanges.filter((range) => !(range.min === min && range.max === max))
      );
    } else {
      setSelectedRatings((prevRanges) => [...prevRanges, { min, max }]);
    }
  }, [setSelectedRatings, selectedRatings]);  

  const onBedroomChange = useCallback((bedroom) => {
    setSelectedBedrooms((prevClickedBedrooms) => {
      let updatedBedrooms;
  
      if (bedroom === 0) {
        return [0];
      } else {
        if (prevClickedBedrooms.includes(bedroom)) {
          updatedBedrooms = prevClickedBedrooms.filter((clickedBedroom) => clickedBedroom !== bedroom);
        } else {
          updatedBedrooms = [...prevClickedBedrooms, bedroom];
        }
  
        if (updatedBedrooms.length === 0) {
          return [0];
        } else if (updatedBedrooms.length === 1 && updatedBedrooms[0] === 0) {
          return updatedBedrooms; 
        } else {
          return updatedBedrooms.filter(bed => bed !== 0);
        }
      }
    });
  }, [setSelectedBedrooms]);
  

  const onBedChange = useCallback((bed) => {
    setSelectedBeds((prevClickedBeds) => {
      let updatedBeds;
  
      if (bed === 0) {
        return [0];
      } else {
        if (prevClickedBeds.includes(bed)) {
          updatedBeds = prevClickedBeds.filter((clickedBed) => clickedBed !== bed);
        } else {
          updatedBeds = [...prevClickedBeds, bed];
        }
  
        if (updatedBeds.length === 0) {
          return [0];
        } else if (updatedBeds.length === 1 && updatedBeds[0] === 0) {
          return updatedBeds; 
        } else {
          return updatedBeds.filter(bed => bed !== 0);
        }
      }
    });
  }, [setSelectedBeds]);
  
  const onBathroomChange = useCallback((bathroom) => {
    setSelectedBathrooms((prevClickedBathrooms) => {
      let updatedBathrooms;
  
      if (bathroom === 0) {
        return [0];
      } else {
        if (prevClickedBathrooms.includes(bathroom)) {
          updatedBathrooms = prevClickedBathrooms.filter((clickedBathroom) => clickedBathroom !== bathroom);
        } else {
          updatedBathrooms = [...prevClickedBathrooms, bathroom];
        }
  
        if (updatedBathrooms.length === 0) {
          return [0];
        } else if (updatedBathrooms.length === 1 && updatedBathrooms[0] === 0) {
          return updatedBathrooms;
        } else {
          return updatedBathrooms.filter(bath => bath !== 0);
        }
      }
    });
  }, [setSelectedBathrooms]);

  const onAmenityChange = useCallback((amenity) => {
    setSelectedAmenities((prevSelectedAmenities) => {
      if (prevSelectedAmenities.includes(amenity)) {
        return prevSelectedAmenities.filter((selectedAmenity) => selectedAmenity !== amenity);
      } else {
        return [...prevSelectedAmenities, amenity];
      }
    });
  }, [setSelectedAmenities]);
  
  const handleClearAll = useCallback(() => {
    clearAllFilters();
  }, [clearAllFilters]);

  return (
    <FilterContainer style={{ position: viewType === 'mapView' ? 'absolute' : 'relative', zIndex: 1, margin: viewType === 'mapView' ? '15px' : '0 15px 0 0' }}>
      <FilterGroup>
        <FilterTitle>Type OF Place</FilterTitle>
        <Radio.Group onChange={onTypeOfPlaceChange} value={typeOfPlace}>
          <Space direction="vertical">
            <Radio value={'any'}>Any</Radio>
            <Radio value={'Entire home/apt'}>Entire Home</Radio>
            <Radio value={'Private room'}>Room</Radio>
          </Space>
        </Radio.Group>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Type OF Property</FilterTitle>
        <Radio.Group onChange={onTypeOfPropertyChange} value={typeOfProperty}>
          <Space direction="vertical">
            <Radio value={'house'}>House</Radio>
            <Radio value={'apartment'}>Apartment</Radio>
            <Radio value={'guest_house'}>Guest House</Radio>
            <Radio value={'hotel'}>Hotel</Radio>
          </Space>
        </Radio.Group>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Price Range</FilterTitle>
        <InputNumber min={0} value={minPrice} onChange={onMinChange} placeholder='Min' /> 
        &nbsp;to&nbsp;
        <InputNumber min={minPrice || 0} value={maxPrice} onChange={onMaxChange} placeholder='Max' /> 
      </FilterGroup>
      {/* <FilterGroup>
        <FilterTitle>Min Stay</FilterTitle>
        {minStay} Nights
        <Slider
          min={1}
          max={5}
          onChange={onMinStayChange}
          value={typeof minStay === 'number' ? minStay : 0}
        />
      </FilterGroup> */}
      <FilterGroup>
        <FilterTitle>Amenities</FilterTitle>
        <AmenitiesCheckBox>
          <Checkbox value="Jacuzzi" onChange={() => onAmenityChange("Jacuzzi")}>Hot Tub/Jacuzzi</Checkbox>
          <Checkbox value="Pool" onChange={() => onAmenityChange("Pool")}>Pool</Checkbox>
          <Checkbox value="Ocean View" onChange={() => onAmenityChange("Ocean View")}>Ocean View</Checkbox>
          <Checkbox value="Air Conditioning" onChange={() => onAmenityChange("Air Conditioning")}>Air Conditioning</Checkbox>
          <Checkbox value="WiFi" onChange={() => onAmenityChange("WiFi")}>WiFi</Checkbox>
          <Checkbox value="Kitchen" onChange={() => onAmenityChange("Kitchen")}>Kitchen</Checkbox>
          <Checkbox value="Heating" onChange={() => onAmenityChange("Heating")}>Heating</Checkbox>
          <Checkbox value="Pets Allowed" onChange={() => onAmenityChange("Pets Allowed")}>Pets Allowed</Checkbox>
        </AmenitiesCheckBox>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Rating</FilterTitle>
        <RatingsContainer>
          <RatingsCheckBoxContainer>
            <Checkbox
              checked={selectedRatings.some(
                (range) => range.min === 4.01 && range.max === 5.00
              )}
              onChange={() => onRatingChange(4.01, 5.00)}
            >
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
            </Checkbox>
          </RatingsCheckBoxContainer>
          <RatingsCheckBoxContainer>
          <Checkbox
              checked={selectedRatings.some(
                (range) => range.min === 3.01 && range.max === 4.00
              )}
              onChange={() => onRatingChange(3.01, 4.00)}
            >
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
            </Checkbox>
          </RatingsCheckBoxContainer>
          <RatingsCheckBoxContainer>
            <Checkbox
              checked={selectedRatings.some(
                (range) => range.min === 2.01 && range.max === 3.00
              )}
              onChange={() => onRatingChange(2.01, 3.00)}
            >
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
            </Checkbox>
          </RatingsCheckBoxContainer>
          <RatingsCheckBoxContainer>
            <Checkbox
              checked={selectedRatings.some(
                (range) => range.min === 1.01 && range.max === 2.00
              )}
              onChange={() => onRatingChange(1.01, 2.00)}
            >
              <img src={rating} alt="star" />
              <img src={rating} alt="star" />
            </Checkbox>
          </RatingsCheckBoxContainer>
          <RatingsCheckBoxContainer>
            <Checkbox
              checked={selectedRatings.some(
                (range) => range.min === 0.01 && range.max === 1.00
              )}
              onChange={() => onRatingChange(0.01, 1.00)}
            >
              <img src={rating} alt="star" />
            </Checkbox>
          </RatingsCheckBoxContainer>
        </RatingsContainer>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Bedrooms</FilterTitle>
        <BedroomButtonContainer>
          <Button
            type={selectedBedrooms.includes(0) ? 'primary' : 'default'}
            onClick={() => onBedroomChange(0)}
          >Any</Button>
          <Button
            type={selectedBedrooms.includes(1) ? 'primary' : 'default'}
            onClick={() => onBedroomChange(1)}
          >1</Button>
          <Button
            type={selectedBedrooms.includes(2) ? 'primary' : 'default'}
            onClick={() => onBedroomChange(2)}
          >2</Button>
          <Button
            type={selectedBedrooms.includes(3) ? 'primary' : 'default'}
            onClick={() => onBedroomChange(3)}
          >3</Button>
          <Button
            type={selectedBedrooms.includes(4) ? 'primary' : 'default'}
            onClick={() => onBedroomChange(4)}
          >4</Button>
          <Button
            type={selectedBedrooms.includes(5) ? 'primary' : 'default'}
            onClick={() => onBedroomChange(5)}
          >5+</Button>
        </BedroomButtonContainer>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Beds</FilterTitle>
        <BedroomButtonContainer>
          <Button
            type={selectedBeds.includes(0) ? 'primary' : 'default'}
            onClick={() => onBedChange(0)}
          >Any</Button>
          <Button
            type={selectedBeds.includes(1) ? 'primary' : 'default'}
            onClick={() => onBedChange(1)}
          >1</Button>
          <Button
            type={selectedBeds.includes(2) ? 'primary' : 'default'}
            onClick={() => onBedChange(2)}
          >2</Button>
          <Button
            type={selectedBeds.includes(3) ? 'primary' : 'default'}
            onClick={() => onBedChange(3)}
          >3</Button>
          <Button
            type={selectedBeds.includes(4) ? 'primary' : 'default'}
            onClick={() => onBedChange(4)}
          >4</Button>
          <Button
            type={selectedBeds.includes(5) ? 'primary' : 'default'}
            onClick={() => onBedChange(5)}
          >5+</Button>
        </BedroomButtonContainer>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Bathroom</FilterTitle>
        <BedroomButtonContainer>
          <Button
            type={selectedBathrooms.includes(0) ? 'primary' : 'default'}
            onClick={() => onBathroomChange(0)}
          >Any</Button>
          <Button
            type={selectedBathrooms.includes(1) ? 'primary' : 'default'}
            onClick={() => onBathroomChange(1)}
          >1</Button>
          <Button
            type={selectedBathrooms.includes(2) ? 'primary' : 'default'}
            onClick={() => onBathroomChange(2)}
          >2</Button>
          <Button
            type={selectedBathrooms.includes(3) ? 'primary' : 'default'}
            onClick={() => onBathroomChange(3)}
          >3</Button>
          <Button
            type={selectedBathrooms.includes(4) ? 'primary' : 'default'}
            onClick={() => onBathroomChange(4)}
          >4</Button>
          <Button
            type={selectedBathrooms.includes(5) ? 'primary' : 'default'}
            onClick={() => onBathroomChange(5)}
          >5+</Button>
        </BedroomButtonContainer>
      </FilterGroup>
      <div>
        <Button size='large' onClick={handleClearAll}>Clear All</Button>
      </div>
    </FilterContainer>
  );
};

export default CompetitorReplacementFilter;
