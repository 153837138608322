import React, { useCallback } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { currencyFormatter } from "util/helper";
import BarTooltip from '../Tooltips/BarTooltip';

const BarDiv = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const CurrentDiv = styled.div`
  width: ${({width}) => width};
  height: 100%;
  background: ${({theme}) => theme.tableBarBlue};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 4px;

  color: ${({theme}) => theme.hoverBlue};
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s2};
`;

const ForecastDiv = styled(CurrentDiv)`
  background: ${({theme}) => theme.quibbleBlueBg};
  justify-content: flex-end;
  padding: 0px 4px 0px 0px;
  border: 1px solid ${({theme}) => theme.tableBarBlue};
  border-left: 1px solid transparent;
  border-style: dashed;
`;

const EmptyDiv = styled.div`
  width: ${({width}) => width};
  height: 100%;
  border: 1px solid ${({theme}) => theme.tableBarBlue};
  border-left: 1px solid transparent;
  border-style: dashed;
`;

const MarketLine = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1.85px solid ${({theme}) => theme.quibblePurple };
  position: absolute;
  left: ${({left}) => left};
`;


const RenderBar = ({current = 0, forecast = 0, market = 0, type, tooltipLabel, adjustmentMenu}) => {


  const showBarData = useCallback(() => {
    if (type==='occupancy') {
      const currentWidth = Number((current * 100).toFixed(0));
      const forecastPercent = Number((forecast * 100).toFixed(0));
      const forecastWidth = forecastPercent - currentWidth;
      const restWidth = 100 - (currentWidth + forecastWidth);
      return (
        <>
          <CurrentDiv width={`${currentWidth}%`} >
            {currentWidth > 10 && `${currentWidth}%`}
            {/* {currentWidth} */}
          </CurrentDiv>
          <ForecastDiv width={`${forecastWidth > 0 ? forecastWidth : 0}%`}>
            {forecastWidth > 10 && `${forecastPercent}%`}
            {/* {forecastPercent} */}
          </ForecastDiv>
          <EmptyDiv width={`${restWidth}%`} />
          {market > 0 && <MarketLine left={`${(market * 100).toFixed(0)}%`} />}
        </>
      )
    }

    const currentWidth = Number(((current/forecast) * 100).toFixed(0));
    const forecastWidth = 100 - currentWidth; 
    
    return (
      <>
        <CurrentDiv width={`${currentWidth > 100 ? 100 : currentWidth}%`} >
          {current > 10 && currencyFormatter(current)}
          {/* {currencyFormatter(current)} */}
        </CurrentDiv>
        <ForecastDiv width={`${currentWidth < 100 ? forecastWidth : 0}%`}>
          {forecastWidth > 10 && currencyFormatter(forecast)}
          {/* {currencyFormatter(forecast)} */}
        </ForecastDiv>
      </>
    )

  }, [current, forecast, market, type]);

  const tooltipProp = type === 'occupancy' ? {
    forecast: `${(forecast * 100).toFixed(0)}%`,
    current: `${(current * 100).toFixed(0)}%`,
    market: `${(market * 100).toFixed(0)}%`,
  }:
  {
    forecast: currencyFormatter(forecast),
    current: currencyFormatter(current),
  }

  return (
    <Tooltip 
        title={<BarTooltip 
        {...tooltipProp}
        label={tooltipLabel} adjustmentMenu={adjustmentMenu}/>}
        > 
      <BarDiv>
        {showBarData()}
      </BarDiv>
    </Tooltip>
  )
}

export default RenderBar;