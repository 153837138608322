import React, { memo, useCallback } from 'react';
import lisitingPhotoPlaceholder from '../../../../../assets/images/listingphotoplaceholder.png';
// import brandPlaceholder from '../../../../../assets/images/brandplaceholder.png';
import { useData } from '../../../../../util/DataStore/DataContext';
import { ViewTitle } from '../OwnerReportModal';
// import { getData } from '../../../../../util/localStorageHelper';
// import filters from '../../../../../components/ReusableComponents/Filters/Filters';
// import useSVGString from '../../../../../customHooks/useSVGString';

import moment from 'moment';

const metric = {
	adr: 'Average Daily Rate (ADR)',
	alos: 'Average Length of Stay',
	ancillaryRevenue: 'Ancillary Revenue',
	blockedDay: 'Blocked Days',
	bookedNights: 'Booked Nights',
	ownerBooking: 'Owner Booking',
	reservation: 'Reservations',
	totalRent: 'Total Rent',
	totalRevenue: 'Total Revenue',
};

export function getQuarter(number, defaultData, year, isYearOnly = false) {
	const quarterMapping = {
		1: '1st Quarter',
		2: '2nd Quarter',
		3: '3rd Quarter',
		4: '4th Quarter',
	};

	if (isYearOnly) return `Year ${year}`;

	if (quarterMapping[number]) {
		return `${quarterMapping[number]} ${year}`;
	}
	return defaultData || 'Monthly Data';
}

export const ComponentToPrint = React.forwardRef(({ setPayload, ...props }, ref) => {
	// let persistedFilter = getData("filters") === null ? filters : getData("filters");

	const payload = setPayload();

	const {
		property,
		month,
		year,
		// brandLogoUrl,
		// companyName,
		currentMonthCurrentYearADR,
		currentMonthCurrentYearOccupancy,
		currentMonthCurrentYearTotalRent,
		keyMetrics,
		keyMetricsByMonth,
		// charts,
	} = payload;

	const { state } = useData();

	const { name, city, imageUrl } = property;
	const MonthYear = `${month} ${year}`;

	const renderMonthTable = useCallback((year) => {
		const { currentMonth, lastMonth } = keyMetricsByMonth;
		const monthKeys = Object.keys(currentMonth);
		if (monthKeys.length) {
			return (
				<>
					<span className='category-title data-title'>{getQuarter(state?.quarter, undefined, props.year, state?.quarter === 5)}</span>
					<div className='table-header'>
						<span className='label metric'>
							Metric
						</span>
						<div className='label-div'>
							<span className='label'>
								{getQuarter(state?.quarter, `${month} ${props.year}`, props.year, state?.quarter === 5)}
							</span>
							<span className='label'>
								{getQuarter(state?.quarter, `${month} ${props.year - 1}`, props.year - 1, state?.quarter === 5)}
							</span>
						</div>
					</div>

					{monthKeys.map((data) => {
						return (
							<div className='table-header row' key={data}>
								<span className='label metric'>
									{metric[data] || '--'}
								</span>
								<div className='label-div row'>
									<span className='label row-month'>
										{ (currentMonth[data] === '$NaN' ? '--' : currentMonth[data]) || '--'}
									</span>
									<span className='label row-month'>
										{ (lastMonth[data] === '$NaN' ? '--' : lastMonth[data]) || '--'}
									</span>
								</div>
							</div>
						);
					})}
				</>
			);
		}
	}, [keyMetricsByMonth, state?.quarter, month, props.year]);

	const renderYearTable = useCallback(() => {
		const { currentYear, last365, lastYear } = keyMetrics;
		const yearKeys = Object.keys(currentYear);
		if (yearKeys.length) {
			return (
				<>
					<span className='category-title data-title'> Yearly Data </span>
					<div className='table-header'>
						<span className='label metric'>
							Metric
						</span>
						<div className='label-div'>
							<span  className='label'>
								{props.year}
							</span>
							<span className='label'>
								{props.year - 1}
							</span>
							<span className='label'>
								Last 365 DAYS
							</span>
						</div>
					</div>
					{yearKeys.map((data) => {
						return (
							<div className='table-header row' key={data}>
								<span className='label metric'>
									{metric[data] || '--'}
								</span>
								<div className='label-div row'>
									<span className='label row-year'>
										{ (currentYear[data] === '$NaN' ? '--' : currentYear[data]) || '--'}
									</span>
									<span className='label row-year'>
										{ (lastYear[data] === '$NaN' ? '--' : lastYear[data]) || '--'}
									</span>
									<span className='label row-year'>
										{ (last365[data] === '$NaN' ? '--' : last365[data]) || '--'}
									</span>
								</div>
							</div>
						);
					})}
				</>
			);
		}
	}, [keyMetrics, props.year]);

	return (
		<div ref={ref} className="owner-report-body">

			<div className="owner-report-content">
				<span className='report-title'> {`Revenue Report - ${getQuarter(state?.quarter, MonthYear, props.year, state?.quarter === 5)}`} </span>
				<div className='generated-date-time'>Generated {moment().format('MM-DD-YY  HH:mm:ss')}</div>
				<div className='header-div'>
					<div className='image-container'>
						<img src={imageUrl || lisitingPhotoPlaceholder} className='property-image' alt="property" />
					</div>
					<div className='details-container'>
						<div className='title-div'>
							<span className='title-name'>{name}</span>
							<span className='title-city'>{city}</span>
						</div>
						<div className='reports-div'>
							<span className='category-title'> {`${getQuarter(state?.quarter, MonthYear, props.year, state?.quarter === 5)} Data`} </span>
							<div className='current-data-container'>
								<div className='current-data'>
									<div className='current-data-value'>{`${currentMonthCurrentYearTotalRent}`}</div>
									<div className='current-data-title'>Total Renvenue</div>
								</div>
								<div className='current-data'>
									<div className='current-data-value'>{`${currentMonthCurrentYearADR}`}</div>
									<div className='current-data-title'>ADR</div>
								</div>
								<div className='current-data'>
									<div className='current-data-value'>{`${currentMonthCurrentYearOccupancy}%`}</div>
									<div className='current-data-title'>Occupancy</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className='header-div'>
					<div className='title-div'>
						<span className='title-name'>{name},</span>
						<span className='title-city'>{city}</span>
					</div>
					<img src={imageUrl || lisitingPhotoPlaceholder} className='property-image' alt="property" />
				</div>
				<img src={brandLogoUrl || brandPlaceholder} className='brand-logo' alt="brand logo" />
				<span className='report-title company-name'> {companyName} </span>
				<span className='report-title'> {`Revenue Report - ${getQuarter(state?.quarter, MonthYear, props.year, state?.quarter === 5)}`} </span>
				<span className='category-title'> {`Current Data - ${getQuarter(state?.quarter, MonthYear, props.year, state?.quarter === 5)}`} </span>
				<div className='current-data-div'>
					<div className='current-data-container'>
						<div> Total Rent: <span className='current-data-value'>{currentMonthCurrentYearTotalRent}</span></div>
						<div> ADR: <span className='current-data-value'>{currentMonthCurrentYearADR}</span></div>
						<div> Occupancy: <span className='current-data-value'>{currentMonthCurrentYearOccupancy}%</span></div>
					</div>
				</div> */}
				{state?.quarter !== 5 && renderMonthTable(year)}
				{renderYearTable()}
				<div className='view-mode'>View Mode - {ViewTitle[state.viewType]}</div>

				<div className='footer'>
					Powered by Quibblerm.com
				</div>
			</div>
		</div>
	);
});

export default memo(ComponentToPrint);
