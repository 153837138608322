import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';

import RenderBar from 'routes/marketAnalytics/sharedComponents/MarketPropertyTable/RenderBar';
import ExtendRenderBar from 'routes/marketAnalytics/sharedComponents/MarketPropertyTable/ExtendRenderBar';
import StarRating from 'components/ReusableComponents/Rating/StarRatings';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import TableMenu from 'components/ReusableComponents/TableMenus/TableMenu';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { showName } from 'util/helper';
import AdjustQueueMenu from 'components/ReusableComponents/TableMenus/AdjustQueueMenu';
import { useDispatch } from 'react-redux';
import { getPropertyCompetitors, getPropertyDetails, getPropertyModel, getPropertyOverviewMetrics, getPropertyPricing, getPropertyTableMetrics, setWorkingQeue } from '../../../appRedux/actions';
import { useData } from '../../../util/DataStore/DataContext';


const WorkingQueueTableBody = styled.div`
  width: 100%;
  max-width: 1600px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: ${({gap}) => gap};
`;


const WorkingQueueTableParentDiv = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 1600px;
  margin: 0 0 0 0;
`;

// const NameButton = styled.button`
//   width: fit-content;
//   height: fit-content;
//   border: none;
//   outline: none;
//   background: none;
//   cursor: pointer;
//   padding: 0px;
// `;

const NameButton = css`
  width: fit-content;
  height: fit-content;;
  border: none;
  margin: 0px;
  padding: 0px;
  background: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    background: inherit;
    color: inherit;
  }
`;

const NameLink = styled(BodyText)`
  margin: 0px;
  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
  }
`;

const ExtendNameLink = styled(NameLink)`
  color: ${({theme}) => theme.expandedTableFont };
`;



const WorkingQueueTable = ({workingQueueData, searchString, setShowPreview, isFetchingPropertyQueue, monthYear}) => {

  const [checkedValues, setCheckedValues] = useState([]);
  
  const dispatch = useDispatch();
  const {state} = useData();


  const onNameClick = useCallback(({propertyId, index}) => {
    const month = monthYear.getMonth() + 1;
    const year =  monthYear.getFullYear();

    dispatch(getPropertyPricing({month, year, propertyId}));
    dispatch(getPropertyDetails({propertyId, params: {month, year}}));
    dispatch(getPropertyOverviewMetrics({propertyId, params: {
      month, year, viewType: state.viewType
    }}));
    dispatch(getPropertyTableMetrics({propertyId, params: {month, year}}));
    dispatch(getPropertyModel({propertyId}));
    dispatch(getPropertyCompetitors({propertyId}));


    setShowPreview({visible: true, id: propertyId, index});
  }, [setShowPreview, dispatch, state, monthYear]);

  const onRemoveQeueFromMenu = (id) => {
    dispatch(setWorkingQeue(workingQueueData.filter((data) => data?.id !== id)));
  };

  const WorkingQueueTable =  [
    {
      label: 'property' ,
      width: '180px',
      align: 'left',
      customRender: (data, arg1, arg2, index ) => (<ButtonWithFunction onClickFunc={onNameClick} ButtonStyle={NameButton} value={{propertyId: data.id, index: index}} label={<NameLink>{showName(data?.key, 14)}</NameLink>} />),
      extendDataIndex: 'key',
      extendCustomRender: (data, arg1, arg2, index ) => (<ButtonWithFunction onClickFunc={onNameClick} ButtonStyle={NameButton} value={{propertyId: data.id, index: index}} label={<ExtendNameLink>{showName(data, 14)}</ExtendNameLink>} />),
    },
    {
      label: 'occupancy',
      width: '240px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel="Occupancy" current={data?.currentYearOccupancy}  forecast={data?.forecastOccupancy} market={data?.marketOccupancy} type="occupancy"/>,
      extendDataIndex: 'lastYearOccupancy',
      extendCustomRender: (data) => <ExtendRenderBar data={data}  type="occupancy"/>,
    },
    {
      label: 'occupancy gap',
      width: '148px',
      align: 'right',
      dataIndex: 'currentYearOccupancyGap',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),
    },
    {
      label: 'adr',
      width: '240px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel='ADR' current={data?.currentYearAdr}  forecast={data?.forecastAdr} />,
      extendDataIndex: 'lastYearAdr',
      extendCustomRender: (data) => <ExtendRenderBar data={data}/>,
    },
    {
      label: 'revenue',
      width: '240px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel='Revenue' current={data?.currentYearTotalRevenue}  forecast={data?.forecastRevenue}/>,
      extendDataIndex: 'lastYearTotalRevenue',
      extendCustomRender: (data) => <ExtendRenderBar data={data}/>,
    },
    {
      label: 'reviews',
      width: '156px',
      align: 'left',
      customRender:(data) => <StarRating yellowLabel={data.averageMarketRating} purpleLabel={data.averageRating}/>
    },
    {
      label: 'blocked days',
      width: '138px',
      align: 'right',
      dataIndex: 'currentYearBlockedDaysShare',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),
      extendDataIndex: 'lastYearBlockedDaysShare',
      extendCustomRender: (data) => (`${(data * 100).toFixed(0)}%`),
    },
    {
      label: 'status',
      width: '138px',
      dataIndex: 'queueStatus',
      customRender: (data) => data,
    },
    {
      label: <CaretDownOutlined />,
      width: '24px',
      customRender: (data) => <TableMenu otherFunctions={onRemoveQeueFromMenu} data={data} monthYear={new Date()} type="workingqueue"/>
    }
  ];

  const onCheckBoxValues = useCallback((values) => {
    setCheckedValues(values);
  }, []);

  const clearCheckValues = useCallback(() => {
    dispatch(setWorkingQeue(workingQueueData.filter((_,index) => !checkedValues.includes(index))))
    setCheckedValues([]);
  }, [dispatch, workingQueueData, checkedValues]);

  const filterWorkingQueueData = workingQueueData.filter((data) => data?.key?.toLowerCase().includes(searchString));

  const checkedValuesData = checkedValues.map((data) => {
    return filterWorkingQueueData[data];
  });
  
  return (
    <WorkingQueueTableBody>
      <WorkingQueueTableParentDiv>
        <AdjustQueueMenu type='workingqueue' adjustQueueData={checkedValuesData} otherFunctions={clearCheckValues}/>
        <QuibbleTable isLoading={isFetchingPropertyQueue} columns={WorkingQueueTable} data={filterWorkingQueueData} expandable={true} checkedRows={checkedValues} withCheckBox={true} pagination={true} paginationSize={12}  onCheckBoxChange={onCheckBoxValues} />
      </WorkingQueueTableParentDiv>
    </WorkingQueueTableBody>
  )
}

export default WorkingQueueTable;