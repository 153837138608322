import {
  USER_PROPERTY_LIST,
  USER_PROPERTY_LIST_SUCCESS,
  USER_PROPERTY_YEARLY_DATA,
  USER_PROPERTY_YEARLY_DATA_SUCCESS,
  UPDATE_PROPERTY_STATUS,
  UPDATE_PROPERTY_STATUS_SUCCESS,
  GET_PROPERTY_DETAIL,
  SET_PROPERTY_DETAIL,
  HIDE_MESSAGE,
  UPDATE_PROPERTY_LOGS,
  UPDATE_BUILDING_TAGS,
  REMOVE_BUILDING_TAGS,
  DOWNLOAD_OWNER_REPORT,
  DOWNLOAD_OWNER_REPORT_SUCCESS,
  UPDATE_DISTRIBUTION_CHANNEEL,
  GET_PROPERTIES_LISTS,
  GET_PROPERTIES_LISTS_SUCCESS,
  GET_PROPERTIES_RESERVATIONS,
  SET_PROPERTIES_RESERVATIONS,
  GET_CITY_ID,
  SET_CITY_ID,
  SET_SYNC_DATA,
  GET_SYNC_DATA,
  GET_CALENDAR_LISTINGS,
  GET_CALENDAR_LISTINGS_SUCCESS,
  GET_PROPERTY_CALENDAR,
  GET_PROPERTY_CALENDAR_SUCCESS,
  GET_PROPERTY_PRICING,
  GET_PROPERTY_PRICING_SUCCESS,
  GET_PROPERTY_DETAILS,
  GET_PROPERTY_DETAILS_SUCCESS,
  GET_PROPERTY_OVERVIEW_METRICS,
  GET_PROPERTY_OVERVIEW_METRICS_SUCCESS,
  GET_PROPERTY_TABLE_METRICS,
  GET_PROPERTY_TABLE_METRICS_SUCCESS,
  GET_PROPERTY_MODEL,
  GET_PROPERTY_MODEL_SUCCESS,
  GET_PROPERTY_COMPETITORS,
  GET_PROPERTY_COMPETITORS_SUCCESS,
  GET_PROPERTY_ANALYTICS,
  GET_PROPERTY_ANALYTICS_SUCCESS,
  GET_PROPERTY_PRICING_RULES,
  GET_PROPERTY_PRICING_RULES_SUCCESS,
  GET_PROPERTY_QUEUE,
  GET_PROPERTY_QUEUE_SUCCESS,
  SET_WORKING_QUEUE_DATA,
  GET_PROPERTY_GROUPS,
  GET_PROPERTY_GROUPS_SUCCESS,
  GET_PROPERTY_GROUP_BY_ID,
  GET_PROPERTY_GROUP_BY_ID_SUCCESS,
  GET_PROPERTY_GROUPS_LISTINGS,
  GET_PROPERTY_GROUPS_LISTINGS_SUCCESS
} from "../../constants/ActionTypes";

/*** Set initial state */
const INIT_STATE = {
  loader                : false,
  textMessage           : '',
  propertyData          : [],
  propertyDataLoading   : false,
  propertyDataLists          : [],
  propertyDataListsLoading   : false,
  propertyDetail        : {},
  propertyDataAnalysis  : {},
  generatingPropertyReport : false,
  dataAnalysisLoaded: false,
  propertiesReservations: [],
  propertyReservationsLoaded: false,
  cityIdsLoading        : false,
  cityIds               : [],
  syncLoader: false,
  syncData: [],
  isFetchingCalendarListing: false,
  calendarListing: [],
  isFetchingPropertyCalendar: false,
  propertyCalendarList: [],
  isFetchingPropertyPricing: false,
  propertyPricing: [],
  isFetchingPropertyDetails: false,
  propertyDetails: {},
  isFetchingPropertyOverviewMetrics: false,
  propertyOverviewMetrics: {},
  isFetchingPropertyTableMetrics: false,
  propertyTableMetrics: {},
  isFetchingPropertyModel: false,
  propertyModel: {},
  propertyAnalytics: [],
  isFetchingPropertyAnalytics: false,
  propertyPricingRules: {},
  isFetchingPropertyPricingRules: false,
  propertyCompetitors: [],
  isFetchingPropertyCompetitors: false,
  propertyQueue: [],
  isFetchingPropertyQueue: false,
  propertyGroups: [],
  isFetchingPropertyGroups: false,
  propertyGroupById: {},
  isFetchingPropertyGroupById: false,
  loading: false,
  propertyGroupsListings: [],
  isFetchingPropertyGroupsListings: false,
};


const PropertyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case USER_PROPERTY_LIST: {
      return {
        ...state,
        propertyDataLoading       : true,
        propertyData : []
      }
    }

    case USER_PROPERTY_LIST_SUCCESS: {
      return {
        ...state,
        propertyDataLoading       : false,
        propertyData : action.payload
      }
    }

    case GET_PROPERTIES_LISTS: {
      return {
        ...state,
        propertyDataListsLoading   : true,
        propertyDataLists : []
      }
    }

    case GET_PROPERTIES_LISTS_SUCCESS: {
      return {
        ...state,
        propertyDataListsLoading       : false,
        propertyDataLists : action.payload
      }
    }

    case GET_PROPERTIES_RESERVATIONS: {
      return {
        ...state,
        propertyReservationsLoaded: false,
        propertiesReservations: [],
      };
    }

    case SET_PROPERTIES_RESERVATIONS: {
      return {
        ...state,
        propertyReservationsLoaded: true,
        propertiesReservations: action.payload,
      };
    }

    case USER_PROPERTY_YEARLY_DATA: {
      return {
        ...state,
        loader       : true,
        dataAnalysisLoaded: false,
        propertyDataAnalysis : {}
      }
    }

    case USER_PROPERTY_YEARLY_DATA_SUCCESS: {
      return {
        ...state,
        loader       : false,
        dataAnalysisLoaded: true,
        propertyDataAnalysis : action.payload
      }
    }

    case UPDATE_PROPERTY_STATUS: {
      return {
        ...state
      }
    }

    case UPDATE_PROPERTY_LOGS: {
      return {
        ...state
      }
    }

    case UPDATE_BUILDING_TAGS: {
      return {
        ...state
      }
    }

    case REMOVE_BUILDING_TAGS: {
      return {
        ...state
      }
    }

    case UPDATE_PROPERTY_STATUS_SUCCESS: {
      return {
        ...state,
        textMessage : action.payload
      }
    }

    case GET_PROPERTY_DETAIL: {
      return {
        ...state,
        loader: true,
        propertyDetail : {}
      }
    }


    case SET_PROPERTY_DETAIL: {
      return {
        ...state,
        loader: false,
        propertyDetail : action.payload
      }
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false, error: '', message: '', textMessage: ''
      }
    }

    case DOWNLOAD_OWNER_REPORT: {
      return {
        ...state,
        generatingPropertyReport: true
      }
    }

    case DOWNLOAD_OWNER_REPORT_SUCCESS: {
      return {
        ...state,
        generatingPropertyReport: false,
      }
    }

    case UPDATE_DISTRIBUTION_CHANNEEL: {
      return {
        ...state,
      }
    }

    case GET_CITY_ID :{
      return {
        ...state,
        cityIdsLoading       : true,
        cityIds : []
      }
    }

    case SET_CITY_ID: {
      return {
        ...state,
        cityIdsLoading       : false,
        cityIds : action.payload
      }
    }

    case GET_SYNC_DATA: {
      return {
        ...state,
        loader: true,
        syncLoader: true,
        syncData: [],
      }
    }

    case SET_SYNC_DATA: {
      return {
        ...state,
        loader: false,
        syncLoader: false,
        syncData: action.payload,
      }
    }

    case GET_CALENDAR_LISTINGS: {
      return {
        ...state,
        isFetchingCalendarListing: true,
      }
    }

    case GET_CALENDAR_LISTINGS_SUCCESS: {
      return {
        ...state,
        calendarListing: action.payload,
        isFetchingCalendarListing: false,
      }
    }

    case GET_PROPERTY_CALENDAR: {
      return {
        ...state,
        isFetchingPropertyCalendar: true,
      }
    }

    case GET_PROPERTY_CALENDAR_SUCCESS: {
      return {
        ...state,
        propertyCalendarList: action.payload,
        isFetchingPropertyCalendar: false,
      }
    }

    case GET_PROPERTY_PRICING: {
      return {
        ...state,
        isFetchingPropertyPricing: true,
      }
    }

    case GET_PROPERTY_PRICING_SUCCESS: {
      return {
        ...state,
        propertyPricing: action.payload,
        isFetchingPropertyPricing: false,
      }
    }

    case GET_PROPERTY_DETAILS: {
      return {
        ...state,
        isFetchingPropertyDetails: true,
      }
    }

    case GET_PROPERTY_DETAILS_SUCCESS: {
      return {
        ...state,
        propertyDetails: action.payload,
        isFetchingPropertyDetails: false,
      }
    }

    case GET_PROPERTY_OVERVIEW_METRICS: {
      return {
        ...state,
        isFetchingPropertyOverviewMetrics: true,
      }
    }

    case GET_PROPERTY_OVERVIEW_METRICS_SUCCESS: {
      return {
        ...state,
        propertyOverviewMetrics: action.payload,
        isFetchingPropertyOverviewMetrics: false,
      }
    }

    case GET_PROPERTY_TABLE_METRICS: {
      return {
        ...state,
        isFetchingPropertyTableMetrics: true,
      }
    }

    case GET_PROPERTY_TABLE_METRICS_SUCCESS: {
      return {
        ...state,
        propertyTableMetrics: action.payload,
        isFetchingPropertyTableMetrics: false,
      }
    }

    case GET_PROPERTY_MODEL: {
      return {
        ...state,
        isFetchingPropertyModel: true,
      }
    }

    case GET_PROPERTY_MODEL_SUCCESS: {
      return {
        ...state,
        propertyModel: action.payload,
        isFetchingPropertyModel: false,
      }
    }

    case GET_PROPERTY_COMPETITORS: {
      return {
        ...state,
        isFetchingPropertyCompetitors: true,
      }
    }

    case GET_PROPERTY_COMPETITORS_SUCCESS: {
      return {
        ...state,
        propertyCompetitors: action.payload,
        isFetchingPropertyCompetitors: false,
      }
    }

    case GET_PROPERTY_ANALYTICS: {
      return {
        ...state,
        isFetchingPropertyAnalytics: true,
      }
    }

    case GET_PROPERTY_ANALYTICS_SUCCESS: {
      return {
        ...state,
        propertyAnalytics: action.payload,
        isFetchingPropertyAnalytics: false,
      }
    }

    case GET_PROPERTY_PRICING_RULES: {
      return {
        ...state,
        isFetchingPropertyPricingRules: true,
      }
    }

    case GET_PROPERTY_PRICING_RULES_SUCCESS: {
      return {
        ...state,
        propertyPricingRules: action.payload,
        isFetchingPropertyPricingRules: false,
      }
    }

    case GET_PROPERTY_QUEUE: {
      return {
        ...state,
        isFetchingPropertyQueue: true,
      }
    }

    case SET_WORKING_QUEUE_DATA: {
      return {
        ...state,
        propertyQueue: action.payload,
      }
    }

    case GET_PROPERTY_QUEUE_SUCCESS: {
      return {
        ...state,
        propertyQueue: action.payload,
        isFetchingPropertyQueue: false,
      }
    }

    // Property Groups
    case GET_PROPERTY_GROUPS: {
      return {
        ...state,
        isFetchingPropertyGroups: true,
      };
    }

    case GET_PROPERTY_GROUPS_SUCCESS: {
      return {
        ...state,
        propertyGroups: action.payload,
        isFetchingPropertyGroups: false,
      };
    }

    case GET_PROPERTY_GROUP_BY_ID: {
      return {
        ...state,
        isFetchingPropertyGroupById: true,
      };
    }

    case GET_PROPERTY_GROUP_BY_ID_SUCCESS: {
      return {
        ...state,
        isFetchingPropertyGroupById: false,
        propertyGroupById: action.payload,
      };
    }

    case GET_PROPERTY_GROUPS_LISTINGS: {
      return {
        ...state,
        isFetchingPropertyGroupsListings: true,
      };
    }

    case GET_PROPERTY_GROUPS_LISTINGS_SUCCESS: {
      return {
        ...state,
        propertyGroupsListings: action.payload,
        isFetchingPropertyGroupsListings: false,
      };
    }

    default:
      return state;
  }
}

export default PropertyReducer;
