import React, {memo} from 'react';
import styled, { css } from 'styled-components';
import { BodyCSS } from '../../Card-Body/BodyCSS';
import { BodyText } from '../../Text/Text';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const PaginationBody = styled.div`
  width: 100%;
  min-height: 68px;
  ${BodyCSS}
  border-radius: 0px;
  padding: 16px;
  border-top: 1px solid ${({theme}) => theme.quibbleHoverGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
`;

const PaginationText = styled(BodyText)`
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  margin: 0px;
`;

const ControlDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 4px;
`;

const PageButton = css`
  width: 36px;
  height: 36px;
  border-radius: 2px;
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  background: ${({theme}) => theme.quibbleHoverGrey};
  box-shadow: none;

  &:hover, &.active {
    color: ${({theme}) => theme.white};
    background: ${({theme}) => theme.quibbleBlue};
  }
`;

const NextButton = css`
  ${PageButton}
  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
    background: none;
    transform: scale(1.2);
  }
  background: none;
`;

const Pagination = ({onSetActivePage, pageRange, activePage, length, isLoading}) => {

  const onArrowClick = (value) => {
    onSetActivePage(value)
  };

  const onDirectPageClick = (value) => {
    onSetActivePage('direct', value)
  };

  return (
    <PaginationBody>
      <PaginationText>
        {pageRange.length && !isLoading ?  `Showing ${pageRange[activePage][0] + 1} to  ${pageRange[activePage][pageRange[activePage].length-1] + 1} of ${length}` : `Loading pagination...`}
      </PaginationText>
      <ControlDiv>
        <ButtonWithFunction value={'prev'} onClickFunc={onArrowClick} ButtonStyle={NextButton} label={<LeftOutlined />} />
        {pageRange.map((_, index) => <ButtonWithFunction key={`${index}`} className={activePage === index && 'active'} disabled={isLoading}  onClickFunc={onDirectPageClick} value={index} ButtonStyle={PageButton} label={index + 1}/>)}
        <ButtonWithFunction value={'next'} onClickFunc={onArrowClick} ButtonStyle={NextButton} label={<RightOutlined />} />
      </ControlDiv>
    </PaginationBody>
  )
}

export default memo(Pagination);