import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BodyText, BodyTitle } from '../../../../../../components/ReusableComponents/Text/Text';
import MinMaxInput from './MinMaxInput';
import { useSelector } from 'react-redux';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { isEmpty } from 'lodash';
import { message } from 'antd';

const MinMaxTitle = styled(BodyTitle)`
  align-self: flex-start;
`;

const MinMaxLabelDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 24px 0 12px 0;
`;

const BodyTextLabel = styled(BodyText)`
  font-size: 18px;
  margin: 0 0 0 44px;
`;


const monthIndex = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}


const Minmax = () => {

  const currencySymbol = localStorage.getItem("currency");
  const { propertyPricingRules } = useSelector(({ property }) => property);
  const [propMinMax, setPropMinMax] = useState({});
  
  const {activeProperty, minMaxPrice, setMinMaxPrice} = useContext(PriceAdjustContext);

  useEffect(() => {
    const limits = propertyPricingRules?.limits;

      if (limits?.length) {
        const newPropMinMax = limits.reduce((outputData, currentData) => {
          return {
            ...outputData,
            [currentData?.month]: [
              currentData.min,
              currentData.max,
            ]
          } 
        }, {})
        setPropMinMax(newPropMinMax);
        return;
      };

      setPropMinMax([]);

  }, [propertyPricingRules]);  

  const MinMaxChange = useCallback((data, month, type) => {

    if (data === null){
      setMinMaxPrice((prev) => (
        {
          [activeProperty] : {
            ...prev[activeProperty],
            [month]: {
              ...prev?.[activeProperty]?.[month],
              [type]: data,
            }
          }
        }
      ));

      return;
    };


    if (type === 'min' && (propMinMax?.[month]?.[0] === data || data === 0)) {
      setMinMaxPrice((prev) => {
        const prevCopy = {...prev};
        delete prevCopy[activeProperty]?.[month]?.min;
        if (isEmpty(prevCopy[activeProperty]?.[month])) delete prevCopy[activeProperty]?.[month];
        if (isEmpty(prevCopy[activeProperty])) delete prevCopy[activeProperty];
        return prevCopy;
      })
      return;
    };

    if (type === 'max' && (propMinMax?.[month]?.[1] === data || data === 0)) {
      setMinMaxPrice((prev) => {
        const prevCopy = {...prev};
        delete prevCopy[activeProperty]?.[month]?.max;
        if (isEmpty(prevCopy[activeProperty]?.[month])) delete prevCopy[activeProperty]?.[month];
        if (isEmpty(prevCopy[activeProperty])) delete prevCopy[activeProperty];
        return prevCopy;
      })
      return;
    };

    setMinMaxPrice((prev) => {
      if (prev?.[activeProperty]?.[month]?.max && type === 'min' && (data > prev?.[activeProperty]?.[month].max)) {
        message.error("min cannot be greater than max")
        return prev;
      };

      if ((!prev?.[activeProperty]?.[month]?.max  && propMinMax?.[month]?.[1]) && type === 'min' && (data > propMinMax?.[month]?.[1])){
        message.error("min cannot be greater than max")
        return prev;
      }

      if (prev?.[activeProperty]?.[month]?.min && type === 'max' && (data < prev?.[activeProperty]?.[month].min)) {
        message.error("max cannot be lesser than min")
        return prev;
      };

      if ((!prev?.[activeProperty]?.[month]?.min && propMinMax?.[month]?.[0]) && type === 'max' && (data < propMinMax?.[month]?.[0])){
        message.error("max cannot be lesser than min")
        return prev;
      }

      return {
        [activeProperty] : {
          ...prev[activeProperty],
          [month]: {
            ...prev?.[activeProperty]?.[month],
            [type]: data,
          }
        }
      }
    });
  },[activeProperty, setMinMaxPrice, propMinMax]);

  const showMinMax = useCallback(() => {
    return Object.keys(monthIndex).map((data) => {
      const updatedValueMin = minMaxPrice?.[activeProperty]?.[data]?.min !== undefined ? minMaxPrice?.[activeProperty]?.[data]?.min : propMinMax?.[data]?.[0];
      const updatedValueMax = minMaxPrice?.[activeProperty]?.[data]?.max !== undefined ? minMaxPrice?.[activeProperty]?.[data]?.max : propMinMax?.[data]?.[1];
      return (
        <MinMaxInput key={monthIndex[data]} month={data} minMax={[updatedValueMin, updatedValueMax]} label={monthIndex[data]} onInputChange={MinMaxChange}/>
      )
    })
  }, [MinMaxChange, activeProperty, minMaxPrice, propMinMax]);

  return (
    <Fragment>
       <MinMaxTitle>
        Min/Max Price
      </MinMaxTitle>
      <MinMaxLabelDiv>
        <BodyTextLabel>
          Minimum ({currencySymbol})
        </BodyTextLabel>
        <BodyTextLabel>
          Maximum ({currencySymbol})
        </BodyTextLabel>
      </MinMaxLabelDiv>
      {showMinMax()}
    </Fragment>
  )
}

export default Minmax;