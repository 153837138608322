import React, { useEffect,useCallback } from 'react';
import styled from 'styled-components';
import Optimize from '../Optimization/Optimize';
import MarketDescription from '../../marketAnalytics/MarketDetails/components/MarketDescription';
import { useDispatch, useSelector } from 'react-redux';
import PropertyDescription from '../../marketAnalytics/PropertyDetails/components/PropertyDescription';
import { getPortfolioPricing, getPortfolioPricingRules, getMarketPricing, getMarketPricingRules, getPropertyPricing, getPropertyPricingRules } from '../../../appRedux/actions';

const PriceAdjustmentMenu = styled.div`
  width: 100%;
  height: fit-content;
  margin: -16px 0 0 0;
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PortfolioAdjustmentGap = styled.span`
  width: 100%;
  margin: 6px;
  height: 0px;
`;


const  AdjustmentMenu = ({adjustmentMenu, propertyId, monthYear, setMonthYear, isProperty, activeMarket, activeProperty}) => {
  const dispatch = useDispatch();

  useEffect(() => {
      const month = monthYear.getMonth() + 1;
      const year =  monthYear.getFullYear();
      dispatch(getPortfolioPricing({month, year,}));
      dispatch(getPortfolioPricingRules());

      dispatch(getMarketPricing({month, year, market:activeMarket}));
      dispatch(getMarketPricingRules({params: {market: activeMarket}}));

      dispatch(getPropertyPricing({month, year, propertyId}));
      dispatch(getPropertyPricingRules({params: {listingId: propertyId}}));

  }, [monthYear, dispatch, activeMarket, activeProperty, propertyId])

  const { propertyDetails, isFetchingPropertyDetails } = useSelector(({ property }) => property);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, [setMonthYear]);
  
  return (
    <PriceAdjustmentMenu>
      {(adjustmentMenu === 'portfolio' || (adjustmentMenu === 'market' && !activeMarket.length) || (adjustmentMenu === 'property' && !activeProperty.length))  ? <PortfolioAdjustmentGap /> : null}
      {(adjustmentMenu === 'market' && activeMarket.length) ?  <MarketDescription monthYear={monthYear} /> : null}
      {(adjustmentMenu === 'property' && activeProperty.length) && !isProperty ?  <PropertyDescription isFetchingPropertyDetails={isFetchingPropertyDetails} propertyDetails={propertyDetails} propertyId={propertyId} monthYear={monthYear}/> : null}
      <Optimize setMonthYear={setMonthYearVal}/>
    </PriceAdjustmentMenu>
  )
}

export default AdjustmentMenu;