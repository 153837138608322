import React from 'react';
import { Space, Button } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import QuibbleTable from '../../../components/ReusableComponents/Table/QuibbleTable';

const GroupTable = ({ filteredPropertyGroups, showDeleteConfirm, onEditGroup }) => {
  const columns = [
    {
      label: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
      align: 'left',
    },
    {
      label: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'left',
    },
    {
      label: 'Tags',
      key: 'listings',
      align: 'left',
      customRender: (group) => {

        if (Array.isArray(group.listings) && group.listings.length > 0) {
          const allTags = group.listings.flatMap((listing) =>
            listing.propertyGroupTags?.split(', ') || []
          );

          const maxTagsToShow = 5;

          if (allTags.length > maxTagsToShow) {
            const displayedTags = allTags.slice(0, maxTagsToShow).join(', ');
            const remainingTags = allTags.length - maxTagsToShow;
            return `${displayedTags}, +${remainingTags} more`;
          }

          return allTags.join(', ');
        } else {
          return '-';
        }
      },
    },
    {
      label: '',
      key: 'action',
      align: 'right',
      customRender: (text, record) => {
        return (
          <Space size="large">
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => showDeleteConfirm(text.id, text.groupName)}
            />
            <Button type="text" icon={<EditOutlined />} onClick={() => onEditGroup(text.id)} />
            <Button type="text" icon={<EyeOutlined />} />
          </Space>
        );
      },
    },
  ];

  return (
    <QuibbleTable
      rowKey="id"
      pagination={{ pageSize: 10, showSizeChanger: true }}
      data={filteredPropertyGroups}
      columns={columns}
    />
  );
};

export default GroupTable;
