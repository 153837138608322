import React, { useState, useEffect } from 'react';

import { ReactComponent  as RatingIcon } from 'assets/images/markethunter/ratings-gray.svg';
import quibbleRm from 'assets/images/quibblerm.png';

import styled from 'styled-components';

import { Modal, Button } from 'antd';


import { LeftOutlined } from '@ant-design/icons';

import ImageCarousel from 'components/ReusableComponents/Image/ImageCarousel';
import DoubleMonthCalendar from './DoubleMonthCalendar';

import GoogleMapReact from 'google-map-react';

const StyledModal = styled(Modal)`
  font-style: normal;
  line-height: normal;
  color: #283334;
  font-family: Commissioner;

  .ant-modal-wrap {
    z-index: 2000;
  }

  .ant-modal-content {
    border-radius: 6px;
    margin: 50px;
  }

  .ant-modal-header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    border-bottom: none;
  }

  .ant-modal-title {
    margin: 0;
    font-size: 40px;
    font-weight: 300;

    .ant-btn {
      margin: 0;
      margin-right: 16px;

      .anticon svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .ant-modal-body {
    padding: 24px;
    padding-bottom: 50px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }

  .ant-modal-close {
    color: #333;
    font-size: 20px;
  }

  .image-holder {
    display: flex;
    flex-wrap: wrap;
  }
`;



const GenericContainer = styled.div`
  margin-bottom: 16px;
    
  border-bottom: 2px solid #B0B0B0;
`;

const TitleText = styled.h1`
  font-size: 32px;
  font-weight: 500;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 10px 0;
`;

const ImageCarouselDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: 2px solid #B0B0B0;
`;

const FirstImage = styled.img`
  width: 50%;
  height: auto;
  max-height: 400px;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

const OtherImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
`;

const OtherImage = styled.img`
  width: calc(50% - 5px);
  height: auto;
  max-height: 195px;
  box-sizing: border-box;
  padding: 0 5px;
`;

const PropertyTitle = styled.h1`
  font-size: 32px;
  font-weight: 300;
`;

const PropertyDetails = styled.h6`
  font-size: 24px;
  font-weight: 300;
`;

const HostInfoContainer = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 5px;
  }
`;

const HostedByText = styled.div`
  font-size: 24px;
  font-weight: 300;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceText = styled.div`
  flex: 1;
  color: #FFF;

  border-radius: 10px 10px 0px 0px;
  border: 1px solid #9747FF;
  background: #9747FF;

  display: flex;
  padding: 8px 32px;
  justify-content: center;
  
  font-size: 48px;

  p {
    margin: auto;

    font-weight: 500;

    span  {
      font-weight: 300;
    }
  }
`;

const ReviewContainer = styled.div`
  flex: 1;
  padding: 8px 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0px 0px 10px 10px;
  border: 1px solid #9747FF;

  p {
    font-size: 32px;
    font-weight: 500;
    margin: auto;

    display: flex;
    align-items: center;

    span {
      font-size: 32px;
      font-weight: 300;

      border-left: 1px solid #B0B0B0;
    }
  }
  
`;

const RatingsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RatingsColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatingsRow = styled.div`
  min-width: 200px;
  padding: 10px 10px 10px 0;
`;

const AmenitiesContainer = styled(GenericContainer)`
  margin-bottom: 16px;
  
  border-bottom: 2px solid #B0B0B0;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin-bottom: 8px;
    flex: 0 0 50%; /* Each list item takes 50% width */
    box-sizing: border-box;
    padding-right: 16px; /* Add some right padding for spacing */
  }
`;

const CalendarDiv = styled.div`
  width: 50%;
`;

const GoogleMapContainer = styled.div`
  width: 100%;
  position: relative;
  height: 418px;
  border: 1px solid black;
`;

const LocationCitytext = styled.div`
  font-size: 18px;
  font-weight: 500;

  margin: 5px 0;
`;

const LocationCitySubText = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;

  margin: 5px 0;
`;

const PoliciesContainer = styled(GenericContainer)`
  li {
    padding: 5px 0;
    color: #283334;
    font-family: Commissioner;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

const TransitContainer = styled(GenericContainer)`
  p {
    padding: 5px 0;
    color: #283334;
    font-family: Commissioner;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

const SelectButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
  .ant-btn {
    width: 120px;
    margin-top: 8px;
  }
`;

const CompetitorInformationModal = ({ open, onCancel, selectedMarker }) => {

  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const [formattedImages, setFormattedImages] = useState([]);

  const toggleShowAllAmenities = () => {
    if (showAllAmenities) {
      setShowAllAmenities(false);
    } else {
      setShowAllAmenities(true);
    }
  };

  useEffect(() => {
    if (selectedMarker?.img_urls) {
      const images = selectedMarker.img_urls.map((url, index) => ({
        imageData: url,
      }));
      setFormattedImages(images);
    }
  }, [selectedMarker]);

  const displayedAmenities = (selectedMarker?.amenities && (showAllAmenities ? selectedMarker.amenities : selectedMarker.amenities.slice(0, 8))) || [];

  const renderMarkers = (map, maps) => {
    const marker = new maps.Marker({
      position: { lat: selectedMarker.lat, lng: selectedMarker.long },
      map,
    });

    return marker;
  }

  return (
    <StyledModal
      title={
        <>
          <Button size="large" type="text" onClick={onCancel} icon={<LeftOutlined />} />
          {selectedMarker?.name ? selectedMarker?.name : 'Property'}
        </>
      }
      open={open}
      onCancel={onCancel}
      footer={null}
      width="100vw"
      height="100vh"
      style={{ top: 0, left: 0, background: '#fff' }}
    >
      <ImagesContainer className="image-holder">
        <FirstImage src={selectedMarker?.image_url || quibbleRm} alt="lakeside 1" />
        <OtherImagesContainer>
          {selectedMarker?.img_urls &&
            selectedMarker.img_urls.slice(0, 4).map((url, index) => (
              <OtherImage key={index} src={url} alt={`lakeside ${index + 2}`} />
            ))}
        </OtherImagesContainer>
        <ImageCarouselDiv>
          {formattedImages.length > 0 && (
            <ImageCarousel imageArray={formattedImages} buttonText={'Show all photos'} />
          )}
        </ImageCarouselDiv>
      </ImagesContainer>

      <DetailsContainer>
        <div style={{ flexGrow: 1 }}>
          <PropertyTitle>
          {selectedMarker?.room_type || ''} in {selectedMarker?.state || ''}
          </PropertyTitle>
          <PropertyDetails>
            {(selectedMarker?.accommodates ?? 0)} guests · {(selectedMarker?.bedrooms ?? 0)} bedroom · {(selectedMarker?.beds ?? 0)} bed · {(selectedMarker?.bathrooms_int ?? 0)} bath
          </PropertyDetails>
          <HostInfoContainer>
            <img src={selectedMarker?.host_picture_url} alt="host" />
            <HostedByText>{selectedMarker?.host_name || ''} </HostedByText>
          </HostInfoContainer>
        </div>
        <PriceContainer>
          <PriceText><p>${selectedMarker?.adr.toFixed(0)} <span>night</span></p></PriceText>
          <ReviewContainer>
            <p>
              {selectedMarker?.rating}
              <RatingIcon style={{ width: '30px', height: '30px', margin: '0 10px' }} fill="#9747FF" alt="rating" /> 
              <span> &nbsp; {selectedMarker?.reviews} reviews</span>
            </p>
          </ReviewContainer>
        </PriceContainer>
      </DetailsContainer>
      <GenericContainer>
        <TitleText>Ratings</TitleText>
        <RatingsDiv>
          <RatingsColumn>
            <RatingsRow>Accuracy: <b>{selectedMarker?.rating_accuracy ?? 0}</b></RatingsRow>
            <RatingsRow>Check-in: <b>{selectedMarker?.rating_checkin ?? 0}</b></RatingsRow>
          </RatingsColumn>
          <RatingsColumn>
            <RatingsRow>Cleanliness: <b>{selectedMarker?.rating_cleanliness ?? 0}</b></RatingsRow>
            <RatingsRow>Communication: <b>{selectedMarker?.rating_communication ?? 0}</b></RatingsRow>
          </RatingsColumn>
          <RatingsColumn>
            <RatingsRow>Location: <b>{selectedMarker?.rating_location ?? 0}</b></RatingsRow>
            <RatingsRow>Value: <b>{selectedMarker?.rating_value ?? 0}</b></RatingsRow>
          </RatingsColumn>
          <RatingsColumn>
            {/* <RatingsRow>Satisfaction: <b>{selectedMarker.rating_accuracy}</b></RatingsRow> */}
          </RatingsColumn>
        </RatingsDiv>
      </GenericContainer>
      <AmenitiesContainer>
        <TitleText>Amenities</TitleText>
        <ul>
          {displayedAmenities.map((amenity) => (
            <li key={`amenity-${amenity}`}>{amenity}</li>
          ))}
        </ul>
        <Button onClick={toggleShowAllAmenities}>{showAllAmenities ? 'Hide' : `Show All ${selectedMarker?.amenities.length || 0} Amenities` }</Button>
      </AmenitiesContainer>
      <GenericContainer>
        <TitleText>Bookings</TitleText>
        <CalendarDiv>
          <DoubleMonthCalendar />
        </CalendarDiv>
      </GenericContainer>
      <GenericContainer>
        <TitleText>Where you’ll be</TitleText>
        <GoogleMapContainer>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM" }}
            defaultCenter={{ lat: selectedMarker?.lat, lng: selectedMarker?.long  }}
            center={{ lat: selectedMarker?.lat, lng: selectedMarker?.long  }}
            defaultZoom={14}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            options={{
              mapTypeId: "terrain",
              clickableIcons: false,
              fullscreenControl: false,
              scrollwheel: false,
              styles: [
                {
                  "featureType": "road",
                  "elementType": "labels",
                  "stylers": [
                    { "visibility": "off" }
                  ]
                },
              ]
            }}
          />
        </GoogleMapContainer>
        <LocationCitytext>
          {selectedMarker?.state || ''}
        </LocationCitytext>
        <LocationCitySubText>
          {selectedMarker?.description || ''}
        </LocationCitySubText>
      </GenericContainer>
      <PoliciesContainer>
        <TitleText>Policies</TitleText>
        <ul>
          {selectedMarker?.additional_house_rules && (selectedMarker?.additional_house_rules.map((policy) => (
            <li key={`policy-${policy}`}>{policy}</li>
          )))}
        </ul>
      </PoliciesContainer>
      <TransitContainer>
        <TitleText>Transit</TitleText>
        <p>
        {selectedMarker?.transit || ''}
        </p>
      </TransitContainer>
      <SelectButtonDiv>
        <Button onClick={onCancel} type="primary">Select</Button>
      </SelectButtonDiv>
    </StyledModal>
  );
};

export default CompetitorInformationModal;