import React, { useEffect, useState } from 'react';
import styled, {css} from 'styled-components';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { ComparisonInfo } from '../../CommonCSS';
import quibbleRm from 'assets/images/quibblerm.png';

import ImageRating from 'components/ReusableComponents/Image/ImageRating';
import piechartPurple from 'assets/images/market/piechartpurple.svg';
import piechartGrey from 'assets/images/market/piechartgrey.svg';

import { lightScrollbar, darkScrollbar } from "components/ReusableComponents/Scrollbar/Scrollbar";
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { currencyFormatter, showName, debouncer } from '../../../../../../util/helper';
import SkLoaderOver from '../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import ImageCarousel from 'components/ReusableComponents/Image/ImageCarousel';

import { Dropdown, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import ReplaceCompetitorModal from './ReplaceCompetitorModal';

import { property } from '../../../../../../services/property';
import { useDispatch } from 'react-redux';
import { getPropertyModel } from '../../../../../../appRedux/actions';

const ComparisonTableDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 20px;
  ${({comparisonDivCss}) => comparisonDivCss};
`;

const PropertyNameText = styled(BodyText)`
  margin: 0 0 22px 0;
`;

const PropertyNameBoldText = styled(BodyText)`
  font-weight: 500;
`;

const SliderLabel = styled(BodyText)`
  align-self: flex-start;
  margin: ${({amenityMargin}) => amenityMargin || '41px 0 0 0'};
  // margin: 40px 0 0 0;
`;

const PercentShareDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  // margin: 24px 0 0 0;
  margin: 68px 0 0 0;

  font-size: ${({theme}) => theme.s6};
  color: ${({theme}) => theme.quibblePurple};
  font-family: Commissioner;
  font-weight: 300;
`;

const PercenShareDivThumbnail = styled(PercentShareDiv)`
  gap: 8px;
  margin: 0 0 0 0;


  font-size: ${({theme}) => theme.s4};
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  font-family: Commissioner;
  font-weight: 300;
`;

const ComparisonList = styled.div`
  height: ${({height}) => height};
  max-height: 100%;
  position: relative;

  ${({showInGrid}) => showInGrid};
  

  overflow: auto;
  overflow-x: hidden;

  ${({comparisonListCss}) => comparisonListCss};
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)}
`;

const CompetitionDiv = css`
  min-width: 342px;
  min-height: 90px; 
  height: 90px;
  margin: 0px;
  border-radius: unset;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;

  border-right: 1px solid ${({theme}) => theme.dirtyGrey};
  border-bottom: 1px solid ${({theme}) => theme.dirtyGrey};
  background: ${({theme}) => theme.quibbleHoverGrey}; 
  ${({unavailable}) => unavailable && `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(240, 240, 240, 0.6);
    pointer-events: none;
  }
`}

  &:hover {
    background: ${({theme, unavailable}) => unavailable ? theme.quibbleHoverGrey : theme.white};
    ${({unavailable}) => unavailable && `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(240, 240, 240, 0.6);
      pointer-events: none;
    }
  `}
  }

  &.active {    
    background: ${({theme, unavailable}) => unavailable ? theme.quibbleHoverGrey : theme.white};
    transition: none;
  ${({unavailable}) => unavailable && `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(240, 240, 240, 0.6);
    pointer-events: none;
  }
`}
  }
`;

const ThumbnailImageDiv = styled.div`
  width: 120px;
  height: 60px;
  position: relative;
  opacity: ${({ unavailable }) => (unavailable ? '40%' : '100%')};

`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;

`;

const ThumbnailDataDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 4px;
`;

const ButtonWithFunctionProperty = styled(ButtonWithFunction)`
  position: relative;
`;

const MoreOptionsDropdown = styled.div`
  color: #697273;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const gridCSS = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
 
const ComparisonTable = ({dataArray=[], isFetchingPropertyModel, isListing, propertyDetails }) => {

  const dispatch = useDispatch();

  const [activeProperty, setActiveProperty] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [currentCompetitorId, setCurrentCompetitorId] = useState();

  const items = [
    {
      key: '1',
      label: (
        <div>Replace</div>
      ),
      onClick: () => showModal(),
    },
    {
      key: '2',
      label: (
        <div>Auto-Replace</div>
      ),
      onClick: () => onAutoReplaceClick(),
    },
    {
      key: '3',
      label: (
        <div>Remove</div>
      ),
      onClick: () => onDeleteClick(),
    },
  ];

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const clickDropDown = (id) => {
    setCurrentCompetitorId(id);
  };

  useEffect(() => {
    if (dataArray.length) {
      setActiveProperty(dataArray[0]);
    }
  }, [dataArray]);
  
  const onPropertyClick = (value) => {
    setActiveProperty(dataArray[value])
  };

  const autoReplaceCompetitor = async () => {
    try {
      const body = {
        sourceId: activeProperty.airBnbId,
        auto: true,
      }
      const propertyId = propertyDetails.id;

      const res = await property.replacePropertyCompetitor({ propertyId, body });

      if (res.status === 200) {
        dispatch(getPropertyModel({propertyId}))
        message.destroy();
        message.success('Updated competitors saved.');
        return;
      }
      
      message.error('Updating competitors failed.');
    } catch (e) {
      message.destroy();
      console.error(e);
      message.error('Updating competitors error.');
    }
  };

  const onAutoReplaceClick = debouncer(autoReplaceCompetitor, 500);

  const deleteCompetitor = async () => {
    try {
      const body = {
        sourceId: activeProperty.airBnbId,
        targetId: null
      }
      const propertyId = propertyDetails.id;

      const res = await property.replacePropertyCompetitor({ propertyId, body });

      if (res.status === 200) {
        dispatch(getPropertyModel({propertyId}))
        message.destroy();
        message.success('Updated competitors saved.');
        return;
      }

      message.error('Updating competitors failed.');
    } catch (e) {
      message.destroy();
      console.error(e);
      message.error('Updating competitors error.');
    }
  };
  
  const onDeleteClick = debouncer(deleteCompetitor, 500);

  const imageArray = activeProperty?.images &&  activeProperty?.images.length ? activeProperty.images.map((data) => ({imageData : data?.image_url, rating: data?.score })) : [{imageData: quibbleRm, rating: 0}];

  const comparisonDivCss = isListing ?  css`
    min-width: 700px;
    width: 700px;
  ` : css`
    min-width: 1040px;
  `;


  const comparisonListCss = isListing ? css`
    min-width: 354px;
  ` : css`
    min-width: 686px;
  `;
  
  return (
    <ComparisonTableDiv comparisonDivCss={comparisonDivCss}>
      <ComparisonInfo>
        <MoreOptionsDropdown>
          <Dropdown onClick={stopPropagation} menu={{ items }} placement="bottomRight" trigger="click">
            <MoreOutlined />
          </Dropdown>
        </MoreOptionsDropdown>
        <PropertyNameText>
          {showName(activeProperty?.name, 25) || 'No Property'}
        </PropertyNameText>
        <ImageCarousel imageArray={imageArray} />
        <PropertyNameBoldText margin={isListing ? "12px 0 -8px 0" :  "12px 0 54px 0"}>
          {`${currencyFormatter(activeProperty?.price || 0)} Night`}
        </PropertyNameBoldText>
        {!isListing && <SliderLabel>
          {/* Image Score: {Number((activeProperty?.images?.reduce((output, current) => output + current.score, 0) /  activeProperty?.images?.length).toFixed(2)) || 0} */}
          Image Score: {Number(activeProperty?.adjusted || 0)?.toFixed(2)}
        </SliderLabel>}
        <SliderLabel>
          Bedrooms: {activeProperty?.bedrooms || 0}
        </SliderLabel>
        <SliderLabel>
          Rating: {(activeProperty?.rating_value || 0).toFixed(1)}
        </SliderLabel>
        <SliderLabel>
          Reviews: {activeProperty?.review_count || 0}
        </SliderLabel>
        {/* <SliderLabel>
          Downtown: 20 minutes
        </SliderLabel> */}
        <SliderLabel>
          Min Stay: {activeProperty?.min_nights || 0} nights
        </SliderLabel>
        {/* <SliderLabel>
          This house has all the amenities
        </SliderLabel> */}
        <SliderLabel  amenityMargin="40px 0 0 0">
          Scenic Views: {activeProperty?.landscape_views ? 'YES' : 'NO'}
        </SliderLabel>
        <SliderLabel amenityMargin="30px 0 0 0">
        Hot Tub/Jacuzzi: {activeProperty?.jacuzzi ? 'YES' : 'NO'}
        </SliderLabel>
        <SliderLabel  amenityMargin="30px 0 0 0">
          Pool: {activeProperty?.pool ? 'YES' : 'NO'}
        </SliderLabel>
        {/* <SliderLabel  amenityMargin="30px 0 0 0">
          Scenic Views: {activeProperty?.landscape_views ? 'YES' : 'NO'}
        </SliderLabel> */}
        <PercentShareDiv>
          <img src={piechartPurple} alt='piechart'/>
          {((activeProperty.market_share * 100) || 0).toFixed(0)}% share
        </PercentShareDiv>
        <SkLoaderOver isLoading={isFetchingPropertyModel} type='calendar'/>
      </ComparisonInfo>
      <ComparisonList comparisonListCss={comparisonListCss} height={isFetchingPropertyModel ? '100%' : 'fit-content'}  showInGrid={dataArray.length > 10 ? gridCSS : ''}>
        {!isFetchingPropertyModel && dataArray.map((data, index) => {
          const imageData = data?.images[0]?.image_url || quibbleRm;
          const imageDataRating = (Number(data?.adjusted || 0))?.toFixed(0);
          return (
            <ButtonWithFunctionProperty className={data.name === activeProperty.name && 'active'} key={`${index} ${data.name}`} ButtonStyle={CompetitionDiv} unavailable={!data.market_share || data.market_share === 0} value={index} onClickFunc={onPropertyClick}
              label={(
                <>
                  <MoreOptionsDropdown>
                    <Dropdown onClick={(e) => { stopPropagation(e); clickDropDown(data.airBnbId); }} menu={{ items }} placement="bottomRight" trigger="click">
                      <MoreOutlined />
                    </Dropdown>
                  </MoreOptionsDropdown>
                  <ThumbnailImageDiv   unavailable={!data.market_share || data.market_share === 0} >
                    <ThumbnailImage src={imageData} alt="property image"/>
                    <ImageRating value={imageDataRating} variant="small"/>
                  </ThumbnailImageDiv>
                  <ThumbnailDataDiv>
                    <BodyText>{showName(data?.name, 20) || 'No Property'}</BodyText>
                    <PercenShareDivThumbnail>
                      <img src={piechartGrey} alt='piechart'/>

                      {data.market_share ? (data.market_share * 100).toFixed(0) + '% share' : 'Unavailable'}


                    </PercenShareDivThumbnail>
                  </ThumbnailDataDiv>
                </>
              )}
             />
          )
        })}
        <SkLoaderOver isLoading={isFetchingPropertyModel} type='full'/>
      </ComparisonList>
      <ReplaceCompetitorModal open={modalVisible} onCancel={handleCancel} currentCompetitorId={currentCompetitorId} />
    </ComparisonTableDiv>
  )
}

export default ComparisonTable;