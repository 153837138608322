import React from 'react';
import styled, { css } from 'styled-components';
import ownerrez from 'assets/images/pms/ownerrez.png';
import hostaway from 'assets/images/pms/hostaway.png';
import guesty from 'assets/images/pms/guesty.png';
import beds from 'assets/images/pms/beds.png';
import hostfully from 'assets/images/pms/hostfully.png';
import villas365 from 'assets/images/pms/365villas.logo.svg';
import fantastic from 'assets/images/pms/fantastic.png';
import lodgify from 'assets/images/pms/lodgify.png';
import hospitable from 'assets/images/pms/hospitable.png';


import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { ImgProp } from '../../../sharedComponents/CommonCss';


const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.75);
`;

const Modal = styled.div`
  width: 720px;
  height: 510px;
  border-radius: 6px;
  background: #005B6A;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  outline: none;
  font-size: 24px;
  color: white;
  position: absolute;
  right: 8px;
  cursor: pointer;
`;

const SelectText = styled.span`
  color: #FFF;
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  margin: 48px 0 0 0;

`;

const PMSSelect = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 36px 0 0 0;
  overflow-x: auto;
`;

const PMSDiv = styled.div`
  width: 300px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PMSItem = css`
  width: 300px;
  height: 70px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s linear;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  background: #FFF;

  &:hover {
    transform: scale(1.05);
    background: #FFF;
    &:disabled {
      transform: unset;
    };
  };

  &:disabled {
    background: #FFF;
  };
`;



const PMSModal = ({onCloseModal, onChangePMS}) => {


  const onCloseClick = () => {
    onCloseModal();
  };

  const onPMSSelect = (value) => {
    onChangePMS(value);
    onCloseModal();
  };

  return (
    <Backdrop>
      <Modal>
        <CloseButton onClick={onCloseClick}>
          x
        </CloseButton>
        <SelectText>
          Select your Property Management System
        </SelectText>
        <PMSSelect>
          <PMSDiv>
            <ButtonWithFunction ButtonStyle={PMSItem} label={<ImgProp src={ownerrez} alt='ownerrez' width='148px' height='24px'/>} value="OwnerRez" onClickFunc={onPMSSelect}/>
            <ButtonWithFunction ButtonStyle={PMSItem} label={<ImgProp src={guesty} alt='guesty' width='110px' height='32px'/> } value='Guesty' onClickFunc={onPMSSelect}/>
            <ButtonWithFunction ButtonStyle={PMSItem} label={<ImgProp src={villas365} alt='365Villas' width='124px' height='32px'/>} value='365Villas' onClickFunc={onPMSSelect} />
            <ButtonWithFunction ButtonStyle={PMSItem} label={<ImgProp src={lodgify} alt='lodgify' width='140px' height='28px'/> } value='Lodgify' onClickFunc={onPMSSelect}/>
            <ButtonWithFunction ButtonStyle={PMSItem} disabled={true} label={<ImgProp style={{filter:'grayscale(100%)'}} src={beds} alt='beds' width='58px' height='24px'/>} value='beds' onClickFunc={onPMSSelect} />
          </PMSDiv>
          <PMSDiv>
            <ButtonWithFunction ButtonStyle={PMSItem} label={<ImgProp src={hostaway} alt='hostaway' width='132px' height='32px'/>} value='Hostaway' onClickFunc={onPMSSelect}/>
            <ButtonWithFunction ButtonStyle={PMSItem} label={<ImgProp src={hostfully} alt='hostfully' width='124px' height='32px'/>} value='Hostfully' onClickFunc={onPMSSelect} />
            <ButtonWithFunction ButtonStyle={PMSItem} disabled={true} label={<ImgProp style={{filter: 'contrast(5%)'}} src={hospitable} alt='hospitable' width='188px' height='32px'/>} value='Hospitable' onClickFunc={onPMSSelect} />
            <ButtonWithFunction ButtonStyle={PMSItem} disabled={true} label={<ImgProp style={{filter:'grayscale(100%)'}} src={fantastic} alt='fantastic' width='132px' height='54px'/>} value='fantastic' onClickFunc={onPMSSelect}/>
          </PMSDiv>
        </PMSSelect>
      </Modal>
    </Backdrop>
  )
}

export default PMSModal;
