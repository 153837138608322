import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import Loader from '../../../../components/ReusableComponents/Loader';
import moment from 'moment';

import { getSubscriptionPlans } from '../../../../appRedux/actions/Subscription';

import Product from './Product';

const PlanContainer = styled.div`
  .ant-radio-button-checked,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1BB3CD;
    border-color: #1BB3CD;
    border-radius: 25px;
  }

  .ant-radio-button-wrapper:hover {
    color: #1BB3CD;
  }

  .ant-radio-button-wrapper {
    border: 0;
    margin: 0;
    border-radius: 25px;
    font-weight: 400;

    span:nth-child(2) {
      padding: 0 1rem;
    }
  }

  .ant-radio-group {
    background: #FFF;
    border-radius: 25px;
    border: 1px solid #E8E8E8;
  }

  span.ant-radio-button  {
    border-radius: 25px;
    padding: 0 1rem;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  display: ${props => props.hidden ? 'none' : 'block'};
`;

const PlanRow = styled(Row)`
  padding: 0.5rem 0;
`;

const PlanFeaturesRow = styled(Row)`
  padding: 0.5rem 0;

  .ant-col {
    margin: 1rem;
  }
`;

const HeaderText = styled.h1`
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  color: #283334;
  margin-top: 1rem;
`;

const PageLoader = styled(Loader)`

`;

const PlanList = [
  {
    name: "RevenueOS",
    price: "$99",
    priceText: "Per month",
    features: [
      "Performance Analytics",
      "Markets Breakdown",
      "Revenue + RevPar Forcast",
      "Occupancy + ADR Watch",
    ],
    additionalFeatures: [
      "<b>Additional $10 per Listing/month</b> <span>(Applied only to property listings with Silent Dynamic Pricing enabled)</span>",
      "Choice Model Pricing",
      "Daily Optimization",
      "Quality Score Comps",
      "Competitive Tracking",
    ],
  },
  {
    name: "Enterprise",
    price: "Contact Us",
    priceText: "Optimized features for your company's needs.",
    features: [
      "All <em>RevenueOS</em> features",
      "75+ property portfolios",
    ],
    additionalFeatures: [
      "Unlimited Property Listing",
    ],
  },
  {
    name: "Consulting",
    price: "Contact Us",
    priceText: "Optimized features for your company's needs.",
    features: [
      "All <em>RevenueOS</em> features",
      "25+ property portfolios",
      "Dedicated revenue manager",
      "Weekly Meetings",
    ],
    additionalFeatures: [
      "Unlimited Property Listing",
    ],
  },
]

export default function Plan() {
  const dispatch = useDispatch();
  
  const subscription = useSelector((state) => state.users.authUserDetails.subscription); 

  // const history = useHistory();
  // const [interval, setInterval] = useState('year');

  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    if (subscription?.status === 'trialing' && subscription?.billingDate) {
      const expirationDate = moment(subscription.billingDate); // Parse the ISO 8601 date string
      const currentDate = moment();
      const remainingDays = expirationDate.diff(currentDate, 'days');
      setDaysLeft(remainingDays);
    }
  }, [subscription]);

  const {
    isFetchingSubscriptionPlans,
    // subscriptionPlans,
  } = useSelector((state) => state.subscription);

  // On component mount
  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, [dispatch]);

  // const changeInterval = (e) => {
  //   setInterval(e.target.value)
  // }

  // const changePlan = (plan) => {
  //   if (plan.name === 'Consultancy') {
  //     return;
  //   } else {
  //     history.push('/setting/profile/plan/payment', { price: plan });
  //   }
  // }

  return (
    <>
      <PlanContainer hidden={isFetchingSubscriptionPlans}>
        <PlanRow justify="center">
          <Col><HeaderText>Choose a Plan</HeaderText></Col>
        </PlanRow>
        {/* <PlanRow justify="center">
          <Col>
            <Radio.Group defaultValue="year" size='large' value={interval} buttonStyle="solid" onChange={changeInterval}>
              <Radio.Button value="month">Billed monthly</Radio.Button>
              <Radio.Button value="year">Billed annually (-20%)</Radio.Button>
            </Radio.Group>
          </Col>
        </PlanRow> */}
        <PlanRow justify="center">
          <Col>
            <p>All pricing is in US dollars.</p>
          </Col>
        </PlanRow>
        <PlanFeaturesRow justify="center">
        {PlanList.map((plan, index) => {
            return (
              <Col key={`plan-${index}`}>
                <Product
                  key={index}
                  name={plan.name}
                  label={plan.price}
                  sublabel={plan.priceText}
                  priceContainerBackground={'#E4F5FC'}
                  features={plan.features}
                  additionalFeatures={plan.additionalFeatures}
                  daysLeft={daysLeft}
                  subscription={subscription}
                />
              </Col>
            )
          })}
          {/* {subscriptionPlans?.filter(plan => plan.interval === interval)
            .sort((a, b) => { return a.order - b.order })
            .map((plan, index) => {
              return (
                <Col key={plan.name}>
                  <Product
                    key={index}
                    name={plan.name}
                    label={plan.label}
                    sublabel={plan.sublabel}
                    priceContainerBackground={plan.background}
                    features={plan.features}
                    buttonLabel={plan.buttonLabel}
                    onChangePlan={() => { changePlan(plan) }}
                    isCurrentPlan={plan.isCurrentPlan}
                    isChangePlan={plan.isChangePlan}
                    isDowngrade={plan.isDowngrade}
                    isUpgrade={plan.isUpgrade}
                  />
                </Col>
              )
            })} */}
        </PlanFeaturesRow>
      </PlanContainer>
      {isFetchingSubscriptionPlans && <PageLoader />}
    </>
  )
}