import React, { Fragment } from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText, BodyTitle } from '../../../components/ReusableComponents/Text/Text';
import blueCheck from 'assets/images/paywall/bluecheck.svg';
import { PrimaryButton } from '../../../components/ReusableComponents/Button/Button';
import { useDispatch } from 'react-redux';
import { manage, signOutUser } from '../../../appRedux/actions';

const SubscribeModalDiv = styled.div`
  ${BodyCSS}
  width: 616px;
  // height: ${({$height}) => $height};
  height: 876px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  align-items: center;
  position: relative;
`;

const SubscribeModalTitle = styled(BodyTitle)`
  font-size: 2.25rem;
`;

const ModaltitleText = styled(BodyText)`
  align-self: center;
  padding: 0 44px;
  text-align: center;
`;

const SubsDetails = styled.div`
  ${BodyCSS}
  width: 456px;
  height: 668px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
`;

const RevenueOS = styled(BodyTitle)`
  font-size: 2rem;
  color: #1BB3CD;
  font-weight: 400;
`;

const Dollar = styled(BodyTitle)`
  font-size: 3rem;
  font-weight: 400;
`;

const BlueDiv = styled.div`
  width: 100%;
  height: 126px;
  background: #E4F5FC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 0 14px 0;
`;

const CheckItems = styled.div`
  width: fit-content;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  margin: 16px 0 0 54px;
  gap: 19px;
`;

const CheckitemsText = styled(CheckItems)`
  margin: 8px 0 0 54px;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const GreySpan = styled.span`
  color: #697273;
`;

const SubscribeButton = styled(PrimaryButton)`
  width: 348px;
  height: 50px;
  margin: 24px 0 0 0;
`;  


const SwitchAccountButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  margin: 8px 0 0 0;
  cursor: pointer;
  color: #697273;
  transition: all 0.2s linear;


  &:hover {
    text-decoration: underline;
  };
`;

const Closebutton = styled(SwitchAccountButton)`
  position: absolute;
  top: 0px;
  right: 8px;
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  };

`;



const SubscribeModal = ({isUpgrade, setSubscriptionMenu, setUpgradePaywall}) => {

  const dispatch = useDispatch();

  const onSubscribeNowClick = () => {
    if (isUpgrade) {
      setUpgradePaywall(false);
      dispatch(manage());
      return;
    };
    setSubscriptionMenu('subscriptionPlan');
  };

  const onLogoutClick = () => {
    if(isUpgrade) setUpgradePaywall(false);
		dispatch(signOutUser());
  };

  const onCloseClick = () => {
    setUpgradePaywall(false);
  };

  return (
    <SubscribeModalDiv $height={isUpgrade ? '800px' : '876px'}>
        {!isUpgrade ? 
        <Fragment>
          <SubscribeModalTitle>
            Your 15-Day Free Trial has expired
          </SubscribeModalTitle>
          <ModaltitleText margin="16px 0 24px 0">
            Your trial has now ended. To keep using Quibble’s RevenueOS, a subscription is required. 
          </ModaltitleText>
        </Fragment>
       : 
        <Fragment>
          <SubscribeModalTitle>
            Upgrade to Insights Pro
          </SubscribeModalTitle>
          <ModaltitleText margin="16px 0 24px 0">
            The feature you are trying to access is not part of the trial period. Consider upgrading to Insight Pro to access this feature. 
          </ModaltitleText>
        </Fragment>
        }
        <SubsDetails>
          <RevenueOS margin="0 0 18px 0">
            RevenueOS
          </RevenueOS>
          <BlueDiv>
            <Dollar>
              $99
            </Dollar>
            <BodyText>
              Per month
            </BodyText>
          </BlueDiv>
          <CheckItems>
            <img src={blueCheck} alt='blue check'/>
            <BodyText>
              Performance Analytics
            </BodyText>
          </CheckItems>
          <CheckItems>
            <img src={blueCheck} alt='blue check'/>
            <BodyText>
              Markets Breakdown
            </BodyText>
          </CheckItems>
          <CheckItems>
            <img src={blueCheck} alt='blue check'/>
            <BodyText>
              Revenue + RevPar Forcast
            </BodyText>
          </CheckItems>
          <CheckItems>
            <img src={blueCheck} alt='blue check'/>
            <BodyText>
              Occupancy + ADR Watch
            </BodyText>
          </CheckItems>
          <CheckItems>
            <BodyText>
            <BoldSpan>Additional $10 per Listing/month </BoldSpan>
            <GreySpan>
              (Applied 
              only to property listings with Silent 
              Dynamic Pricing enabled)
            </GreySpan> 
            </BodyText>
          </CheckItems>
          <CheckitemsText>
            Choice Model Pricing
          </CheckitemsText>
          <CheckitemsText>
            Daily Optimization
          </CheckitemsText>
          <CheckitemsText>
            Quality Score Comps
          </CheckitemsText>
          <CheckitemsText>
            Competitive Tracking
          </CheckitemsText>
          <SubscribeButton onClick={onSubscribeNowClick}>
            {isUpgrade ? 'Add your payment information' :  'Subscribe Now'}
          </SubscribeButton>
        </SubsDetails>
        <SwitchAccountButton onClick={onLogoutClick}>
          <BodyText>
            Log Out
          </BodyText>
        </SwitchAccountButton>
        {isUpgrade && <Closebutton onClick={onCloseClick}>
          <BodyTitle>
            x
          </BodyTitle>
        </Closebutton>}
    </SubscribeModalDiv>
  )
}

export default SubscribeModal;