import React, {memo} from "react";
import styled, { css, useTheme } from "styled-components";
import { TableRowBody, TableRowCheckBox, TableRowDiv } from "./CommonCSS";
import { isObject } from "lodash";
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import CheckBox from "../../Checkbox/CheckBox";
import TableMenu from "../../TableMenus/TableMenu";
import useSelection from "antd/lib/table/hooks/useSelection";

const TableRowDataDiv = styled(TableRowDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  color: ${({activeColor}) => activeColor};
  gap: 4px;
  align-items: ${({align}) => align};  
  position: relative;
  padding: ${({padding}) => padding};
  display: ${({display}) => display};
`;

const IconButton = css`
  width: 32px;
  max-width: 32px;
  min-height: 48px;
  height: 100%;
  border: none;
  margin: 0px;
  padding: 0px;
  background: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: ${({alignItems}) => alignItems};
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 16px 0px;
  font-size: ${({theme}) => theme.s2};

  &:hover {
    background: inherit;
    color: inherit;
  }
`;

const DownIconOutlined = styled(DownOutlined)`
  margin: 4px 0 0 0;
`;

const UpIconOutlined = styled(UpOutlined)`
  margin: 4px 0 0 0;
`;

const RowDataDiv = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0px;
  text-align: ${({align}) => align};
`;

const ExpandedDiv = styled(RowDataDiv)`
  color: ${({theme}) => theme.expandedTableFont};
`;


const renderData = (dataToRender, customRender, isSelected, activeColor, index, colIndex) => {
  if(dataToRender === null || dataToRender === undefined) {
    return '';
  }
  if (customRender) {
    return customRender(dataToRender, isSelected, activeColor, index, colIndex)
  }
  
  return isObject(dataToRender) ? '-' : dataToRender;
};

const renderRightClick = (rightClickRender, clickMenuProps) => {
  return rightClickRender(clickMenuProps);
};


const TableRowData = ({RowCSS, activeColor, dataIndex, greyed, withCheckBox, columns=[], data={}, expandable, expandedRows, checkedRows, onExpandIconClick, onCheckClick, onRowClick, keyData, rowIndex,contextMenuHandle,clickMenuProps, selectedList=[], disabledDates, maxHeight, caretCheckedValues, monthYear, type, columnFilter}) => {  

  const isSelected = selectedList.includes(data[keyData]);
  const isDisabled = disabledDates.includes(data[keyData]);
  const isGreyed = greyed.includes(data[keyData]);

  const theme = useTheme();

  const onTableRowclick = () => {
    if (isDisabled) return;
    const dataVal = {isVisible: false}
    contextMenuHandle(dataVal);
    
    const toReturnData = data[keyData] || '';
    

    if(!onRowClick) return;

    onRowClick(toReturnData);
  }

  const onExpandClick = (value) => {
    if (isDisabled) return;
    onExpandIconClick(value)
  };

  const onCheckClickFunc = (value) => {
    if (isDisabled) return;
    onCheckClick(value, dataIndex);
  };

  const onHandleContextMenu = (val, index, label) => (e) => {
    if (isDisabled) return;
    e.preventDefault();
    const dataVal = {x: e?.clientX + 18, y: e?.clientY + 18, label, isVisible: true, index: index, data: val};
    contextMenuHandle(dataVal);
  };

  return (
    <>
    <TableRowBody  id={`tablerow${rowIndex}`} background={isDisabled || isGreyed ? '#F0F0F0' : theme.bodyBg} RowCSS={RowCSS}  maxHeight={expandedRows.includes(rowIndex) ? '80px' : (maxHeight || '48px')} className={ isSelected && 'active'} onClick={onTableRowclick}>
      {withCheckBox && <TableRowCheckBox><CheckBox checked={checkedRows.includes(dataIndex)} onCheckBoxChange={onCheckClickFunc}/></TableRowCheckBox>}
      {columns.length ? 
        columns.map((columnData, index) => {
          const expanding = index === 0 && expandable===true; 
          const indexIncluded = expandedRows.includes(rowIndex);
          const columnValue = columnData.dataIndex ? data[columnData.dataIndex] : data;
          const isVisible = caretCheckedValues.includes(columnData.label);
          return (
            <TableRowDataDiv id={`tablecol${index}`} maxHeight={maxHeight} onContextMenu={columnData?.rightClickRender ? onHandleContextMenu(columnValue, rowIndex, columnData?.label) : null}  activeColor={isSelected ? activeColor :'unset'} padding={expanding ? '16px 12px 16px 32px' : '16px 12px'}  key={`${index} ${columnData.label}`} width={columnData.width || 'fit-content'} display = {columnFilter && (isVisible ? (columnData.display || 'flex'): "none")}>
              {expanding && <ButtonWithFunction onClickFunc={onExpandClick} ButtonStyle={IconButton} alignItems={indexIncluded ? 'flex-start' : 'center'} value={rowIndex} label={indexIncluded ? <UpIconOutlined /> : <DownIconOutlined />} />}
              <RowDataDiv align={columnData.align ||'center'} >{renderData(columnValue, columnData?.customRender, isSelected, activeColor, rowIndex, index) || '-'}</RowDataDiv>
              {columnData?.rightClickRender && rowIndex === clickMenuProps.index ? renderRightClick(columnData?.rightClickRender, clickMenuProps) : null}
              {indexIncluded && <ExpandedDiv align={columnData.align  ||'center'} >{renderData(columnData.extendDataIndex ? data[columnData.extendDataIndex] : data, columnData?.extendCustomRender) || '-'}</ExpandedDiv>}
            </TableRowDataDiv>
            )
        })
            :
          <TableRowDiv width={'fit-content'} align={'center'} >
            Placeholder Text
          </TableRowDiv>
      }
      {columnFilter &&
        <TableRowDataDiv width={"30px"} align={'center'}><TableMenu data={data} monthYear={monthYear} type={type} ></TableMenu></TableRowDataDiv>
      }
    </TableRowBody>
    </>
  )
}

export default memo(TableRowData);