import React from 'react';
import styled from 'styled-components';

import {BodyTitle} from 'components/ReusableComponents/Text/Text';
import searchIcon from 'assets/images/generic/search.svg';



const WorkingQueueFilterDiv = styled.div`
  width: 100%;
  max-width: 1600px;
  height: 48px;
`;

const WorkingQueueFilterFlex = styled.div`
  min-width: 1600px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;


const SearchImage = styled.img`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 16px;
  margin: auto;
`;

const SearchInput = styled.input`
  width: 300px;
  height: 100%;
  padding: 0 0 0 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-weight: 300;
  font-size: ${({theme}) => theme.s4};

  ::placeholder {
    color: #B0B0B0;
    opacity: 1; /* Firefox */
  }
`;


const WorkingQueueFilter = ({setSearchString, numberOfQueue}) => {

  const onInputChange = (e) => {
    e.preventDefault();
    const searchString = e.target.value;
    setSearchString(searchString);
  };


  return (
    <WorkingQueueFilterDiv>
      <WorkingQueueFilterFlex>
        <BodyTitle>
          
          {numberOfQueue > 0 ? numberOfQueue > 1  ? `${numberOfQueue} Properties` : `1 Property` : 'No current Queue'} 
        </BodyTitle>
        <SearchInput type="search" onChange={onInputChange} placeholder='Search' />
        <SearchImage src={searchIcon}  alt="search icon"/>
      </WorkingQueueFilterFlex>
    </WorkingQueueFilterDiv>
  )
}

export default WorkingQueueFilter;