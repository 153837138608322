import React, { useState } from 'react';
import styled from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle } from 'components/ReusableComponents/Text/Text';
import { isEmpty } from 'lodash';
import { Pie, PieChart } from 'recharts';
import { renderActiveShape } from './RenderActiveShape';
import RenderActiveLogo from './RenderActiveLogo';
import SkLoaderOver from '../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';


const PriceRevenueGraphDiv = styled.div`
  ${BodyCSS}
  width: 890px;
  min-width: 890px;
  height: 100%;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  position: relative;
`;


const PiechartDiv = styled.div`
  width: fit-content;
  height: fit-content;
  align-self: center;
  margin: 32px 0 0 0;
  position: relative;
`;


const Reservations = ({reservationPerSource, isLoading}) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const {name, fill} = !isEmpty(reservationPerSource)  ? reservationPerSource[activeIndex] : { _id: 'Loading', name: 'No Reservation Data Available', fill: 'aF8BC43' }; 

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <PriceRevenueGraphDiv>
      <BodyTitle>
          Reservation By Source
      </BodyTitle>
      <PiechartDiv>
        <PieChart width={800} height={400}>
          {
            reservationPerSource ? (<Pie
            dataKey="value"
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            data={reservationPerSource}
            innerRadius={80}
            outerRadius={150}
          />) : null
          }
        </PieChart>
      </PiechartDiv>
      <RenderActiveLogo name={name} fill={fill}  />
      <SkLoaderOver isLoading={isLoading} type='card' />
    </PriceRevenueGraphDiv>
  )
}

export default Reservations;