import React, { useCallback, memo } from 'react';
import styled from 'styled-components';
import { Button } from '../Button/Button';

const ButtonWithFuncStyle = styled(Button)`
  ${({ButtonStyle}) => ButtonStyle};
`;

const ButtonWithFunction = ({ButtonStyle, label, onClickFunc, value, ...otherprops}) => {

  const onClick = useCallback(() => {
    if(onClickFunc) {
      onClickFunc(value);
      return;
    }
    return;
  }, [onClickFunc, value])

  return (
    <ButtonWithFuncStyle ButtonStyle={ButtonStyle} onClick={onClick} {...otherprops}>
      {label}
    </ButtonWithFuncStyle>
  )
}

export default memo(ButtonWithFunction);