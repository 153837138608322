import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import TableRowTitle from './Components/TableRowTitle';
import TableRowData from './Components/TableRowData';
import { lightScrollbar, darkScrollbar } from '../Scrollbar/Scrollbar';
import {BodyTitle} from 'components/ReusableComponents/Text/Text';
import SkLoaderOver from '../SkeletonLoader/SkLoaderOver';
import { memo } from 'react';
import Pagination from './Components/Pagination';
import { string } from 'prop-types';

const QuibleTableBody = styled.div`
  width: 100%;
  // min-width: 1585px;
  height: ${({height}) => height};
  max-width: 1800px;
  min-height: 400px;
  overflow-y: ${({overflow}) => overflow};
  overflow-x: hidden;
  position: relative;
  border-radius: inherit;
  background: white;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const NoData = styled(BodyTitle)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
  width: fit-content;
  height: fit-content;
`;


const QuibbleTable = ({columns, data=[], withCheckBox,activeColor, onCheckBoxChange, greyed=[], pagination=false, paginationSize=10, isLoading=false, onRowClick, keyData, expandable=false, selectedList, checkedRows=[], disabledDates=[], RowCSS, maxHeight, monthYear, type, columnFilter}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [pageRange, setPageRange] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const intPaginationSize = parseInt(paginationSize < 3 ?  3 : paginationSize);
  const [clickMenuProps, setClickMenuProps] = useState({x: 0, y: 0, isVisible: false, index: null,  data: null});
  const [sort, setSort] = useState({
    type: 'normal',
    key: null,
  });
  const [caretCheckedValues, setCaretCheckedValues] = useState(columns.map(column => column.label));

  
  const numberOfPages = useMemo(() => Math.ceil(data.length/intPaginationSize),[data.length, intPaginationSize]);
  
  useEffect(() => {
    if (pagination) {
      const rangeArray = [...Array(numberOfPages).keys()].map((_, index) => {
        const newData = data.map((_, index) => ({dataIndex: index}));
        return newData.slice(intPaginationSize * index, (index === (numberOfPages - 1) ? ( newData.length) : intPaginationSize * (index + 1))).map((data) => data.dataIndex);
      });
      setPageRange(rangeArray);
    }
  }, [pagination, data, numberOfPages, intPaginationSize]);

  useEffect(() => {
    if (data.length) {
    setActivePage(0);
    }
  }, [data.length])

  const onExpandClick = useCallback((value) => {
    setExpandedRows((prev) => {
      if (prev.includes(value)) {
        return prev.filter((data) => data !== value);
      }
      return [...prev, value]
    })
  }, []);

  const onCheckClick = useCallback((checked, index) => {

      if (checked) {
        const newCheckedRows = [...checkedRows, index];
        if (onCheckBoxChange) onCheckBoxChange(newCheckedRows);
        return newCheckedRows;
      }; 
      
      const newCheckedRows = checkedRows.filter((data) => data!==index);
        if (onCheckBoxChange) onCheckBoxChange(newCheckedRows);
      return newCheckedRows;
      
    
  }, [onCheckBoxChange, checkedRows]);

  const onClickCheckAll = useCallback((value) => {
    if (value === 'all' || value === 'line') {
      if (onCheckBoxChange) onCheckBoxChange([]);
      return;
    };

    const newCheckedRows = data.map((_,index) => index);
    if (onCheckBoxChange) onCheckBoxChange(newCheckedRows);
    return;

  }, [data, onCheckBoxChange]);

  const onSetActivePage = useCallback((type, value) => {
    if (type === 'prev') {
      setActivePage((prev) => {
        if (prev === 0) return prev;
        return prev - 1;
      })
      return;
    };
    if (type === 'next') {
      setActivePage((prev) => {
        if (prev === pageRange.length - 1) return prev;
        return prev + 1;
      })
      return;
    };
    setActivePage(value);
  }, [setActivePage, pageRange.length]);

  const onHandleContextMenu = (dataVal)  => {
    setClickMenuProps((prev) => ({...prev, ...dataVal}));
  };

  const sortedData = useMemo(() => {
    const newData = [...data];

    if (sort.type  === 'normal') {
      return data;
    };

    if (sort.type  === 'ascend') {
      return newData.sort((a,b) => {
        if (typeof(a[sort.key]) === string && b[sort.key] === string) {
          return a[sort.key].tolowerCase() > b[sort.key].tolowerCase() ? 1 : -1
        };

        return a[sort.key] > b[sort.key] ? 1 : -1;
      });
    };

    if (sort.type  === 'descend') {
      return newData.sort((a,b) => {
        if (typeof(a[sort.key]) === string && b[sort.key] === string) {
          return a[sort.key].tolowerCase() > b[sort.key].tolowerCase() ? -1 : 1
        };

        return a[sort.key] > b[sort.key] ? -1 : 1;
      });
    };
  }, [data, sort]);

  const tableData = pageRange[activePage]?.length && pagination ? sortedData.slice(pageRange[activePage][0], (pageRange[activePage][pageRange[activePage].length-1] + 1)) : sortedData;

  return (
    <Fragment>
      <QuibleTableBody id="quibbleTable" height={pagination ? 'calc(100% - 68px)' : '100%'} overflow={isLoading ? 'hidden': 'auto'}>
        <TableRowTitle  columns={columns} onClickCheckAll={onClickCheckAll} withCheckBox={withCheckBox} checkedRows={checkedRows} sort={sort} setSort={setSort}  length={data.length} caretCheckedValues={caretCheckedValues} setCaretCheckedValues={setCaretCheckedValues} columnFilter={columnFilter}/>
          {tableData && tableData.length ? tableData.map((tableData, index) => 
          <TableRowData 
            rowIndex={index} 
            dataIndex={activePage * paginationSize + index}
            key={`${index} ${tableData.date}`} 
            expandable={expandable} 
            expandedRows={expandedRows}
            withCheckBox={withCheckBox}
            checkedRows={checkedRows}
            onExpandIconClick={onExpandClick}
            onCheckClick={onCheckClick}
            columns={columns} 
            data={tableData} 
            onRowClick={onRowClick} 
            keyData={keyData}
            selectedList={selectedList}
            RowCSS={RowCSS}
            activeColor={activeColor}
            contextMenuHandle={onHandleContextMenu}
            clickMenuProps={clickMenuProps}
            disabledDates={disabledDates}
            greyed={greyed}
            maxHeight={maxHeight}
            caretCheckedValues= {caretCheckedValues}
            setCaretCheckedValues={setCaretCheckedValues}
            monthYear={monthYear}
            type={type}
            columnFilter={columnFilter}
            />) :
          <NoData margin="auto">No Data</NoData>}
        <SkLoaderOver isLoading={isLoading} type='table' />
      </QuibleTableBody>
      {pagination && <Pagination isLoading={isLoading} onSetActivePage={onSetActivePage} pageRange={pageRange} activePage={activePage} length={data.length}/>}
    </Fragment>
  )
}

export default memo(QuibbleTable);