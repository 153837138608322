import React, { Fragment }  from 'react';
import styled from 'styled-components';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import CheckBox from 'components/ReusableComponents/Checkbox/CheckBox';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { message } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const NoPMSBody = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
`;

const CheckBoxDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const NoPMSButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px;
`;

const NoPMS = ({noPMSChecked, setNoPMSChecked, onSubmitForm}) => {

  const history = useHistory();

  const onCheckBoxChange = (checked) => {
    setNoPMSChecked(checked);
  };

  const onFinishSignup = () => {
    message.success('Signup Successful');
    history.push('/');
 };

  return (
    <Fragment>
      <NoPMSBody>
        <BodyText>
          Don't have a PMS?
        </BodyText>
        <CheckBoxDiv>
          <CheckBox checked={noPMSChecked} onCheckBoxChange={onCheckBoxChange} />
          <BodyText>
            Sign up for Market Hunter Only
          </BodyText>
        </CheckBoxDiv>
      </NoPMSBody>
      <NoPMSButton disabled={!noPMSChecked} onClick={onFinishSignup} >
        Finish Signup
      </NoPMSButton>
    </Fragment>
  )
}

export default NoPMS;