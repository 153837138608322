import moment from "moment";
import { currentDate } from "../constants/GlobalConstant";
import { Tooltip } from "antd";


export const currencyFormatter = (value) => {
  const currencySymbol = localStorage.getItem("currency");
  if (value === undefined || value === null || value === "") {
    return "-";
  }
  return `${currencySymbol}${Number(value)
    .toFixed(0)
    // .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const abbreviationFormatter = (value) => {
  if ((value >= 1000 && value < 1000000) || (value < 0 && value <= -1000 && value > -1000000)) {
    return (value / 1000).toFixed(value >10000 ? 0 : 1).replace(/\.0$/, '') + 'k';
  }
  if ((value >= 1000000) || (value < 0 && value <= -1000000)) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  return value;
}



export const currencyFormatterV2 = (value, currency='') => {
  const currencySymbol = currency
  if (value === undefined || value === null || value === "" || isNaN(value)) {
    return "-";
  }

  const numValue = Number(value)
  return `${numValue < 0 ? '-' : ''}${currencySymbol}${(Math.abs(numValue))
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const currencyAbbreviationFormatter= (value, currency='') => {
  const currencySymbol = currency
  if (value === undefined || value === null || value === "" || isNaN(value)) {
    return "-";
  }

  const numValue = Number(value)
  return `${numValue < 0 ? '-' : ''}${currencySymbol}${abbreviationFormatter((Math.abs(numValue)))}`;
};


/*check signs of the value*/

export const checkSign = (value) => {
  if (value === 0 || !value) {
    return 'zero'
  } 
  if (value > 0) {
    return 'positive'
  }
  if (value < 0) {
    return 'negative'
  }
};

/*search debouncer */

export const debouncer = (func, timeinms) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeinms);
  };
}

/* parse query string */

export const parseQuery = (queryString) => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const formatDate = (date) => {
  const YYYY = date.getFullYear();
  const MM  = ('0'+(date.getMonth()+1)).slice(-2);
  const DD  = date.getDate();
  return `${YYYY}-${MM}-${String(DD).length < 2 ? '0' + DD : DD}`
}

export const getDaysFromDayAndMonth = (weekOfDay, selectedMonth, year) => {
  const selectMonthDate = new Date(year, selectedMonth, 0);
  const numberOfDays = new Date(year, selectedMonth + 1, 0).getDate();

  if (weekOfDay === 7) {
    const selectedDays = [...Array(numberOfDays).keys()].map((_) => {
      const dayOfWeek = new Date(selectMonthDate.setDate(selectMonthDate.getDate() + 1));
      return formatDate(dayOfWeek);
    }).filter((date) => moment(date, "YYYY-MM-DD") >= moment(currentDate, "YYYY-MM-DD"));
    
    return selectedDays;
  }

  const selectedDays = [...Array(numberOfDays).keys()].reduce((outputData, _) => {
        const dayOfWeek = new Date(selectMonthDate.setDate(selectMonthDate.getDate() + 1));
        if(dayOfWeek.getDay() === weekOfDay) {
          return [...outputData,formatDate(dayOfWeek)]
        }
        return outputData;
    }, []).filter((date) => moment(date, "YYYY-MM-DD") >= moment(currentDate, "YYYY-MM-DD"));

  return selectedDays;
};

export const  convertDateToUTC = (date) => { return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); }

export const showName = (name, limit=20) => {
  if (name?.length && name?.length > limit) {
    return (
      <Tooltip title={name}>
        {`${name?.slice(0,limit-3)}...`}
      </Tooltip>
    ) 
  }
  return name || '';
};

export const  dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};