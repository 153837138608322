import React, { Fragment, useEffect, useState } from 'react';
// import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropertyDescription from 'routes/marketAnalytics/PropertyDetails/components/PropertyDescription';
import WorkingQueuePreviewFilter from '../subComponents/WorkingQueuePreview/WorkingQueuePreviewFilter';
// import PropertyMenuOptimize from 'routes/marketAnalytics/PropertyDetails/components/subComponents/PropertyMenu/PropertyMenuOptimize';
// import PriceAdjustment from '../../priceAdjustment/PriceAdjustment';
import PropertyMenu from '../../marketAnalytics/PropertyDetails/components/PropertyMenu';
import PropertyContext from '../../marketAnalytics/PropertyDetails/components/PropertyContext';
import PropertyMap from '../../marketAnalytics/PropertyDetails/components/PropertyMap';
// import { now } from '../../../constants/GlobalConstant';


// const PropertyMenuBody = styled.div`
//   width: 100%;
//   max-width: 1776px;
//   margin: 0px 0 0 0;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

const WorkingQueuePreview = ({showPreview, setShowPreview, monthYear, setMonthYear }) => {


  const { propertyDetails, isFetchingPropertyDetails, propertyModel, propertyOverviewMetrics } = useSelector(({ property }) => property);
  const {address} = propertyOverviewMetrics;

    //model tab state
    const [model, setModel] = useState({
      price: 1,
      bedrooms: 1,
      ratingValue: 0,
      reviewCount: 0,
      // downtown: 0,
      minNights: 0,
      jacuzzi: false,
      pool: false,
      landscapeViews: false,
      imageScore: 0,
    });
  

    //get model properties
    useEffect(() => {
      setModel({
        price: propertyModel?.price || 1,
        bedrooms: propertyModel?.bedrooms || 1,
        ratingValue: propertyModel?.rating_value || 0,
        reviewCount: propertyModel?.review_count || 0,
        // downtown: propertyModel?.dist || 0,
        minNights: propertyModel?.min_nights || 0,
        jacuzzi: propertyModel?.jacuzzi || false,
        pool: propertyModel?.pool || false,
        landscapeViews: propertyModel?.landscape_views || false,
        imageScore: propertyModel?.adjusted > 0 ? propertyModel?.adjusted :
        Number((propertyModel?.images?.reduce((output, current) => output + current.score, 0) /  propertyModel?.images?.length).toFixed(2)) || 0,
      })
  }, [propertyModel]);

  return (
    <Fragment>
      <PropertyContext.Provider value={{model, setModel}}>
      <WorkingQueuePreviewFilter showPreview={showPreview} setShowPreview={setShowPreview} showPreviewIndex={showPreview.index}  monthYear={monthYear}/>
      <PropertyDescription isFetchingPropertyDetails={isFetchingPropertyDetails} propertyDetails={propertyDetails}  monthYear={monthYear} margin="0 0 0 0"/>
      <PropertyMenu propertyModel={propertyModel} propertyId={showPreview?.id}  monthYear={monthYear} setMonthYear={setMonthYear} isFetchingPropertyDetails={isFetchingPropertyDetails} propertyDetails={propertyDetails} />
      <PropertyMap propertyDetails={propertyDetails} isFetchingPropertyDetails={isFetchingPropertyDetails} address={address}/>
      </PropertyContext.Provider>
    </Fragment>
  )
}

export default WorkingQueuePreview;