import React from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import { markets } from '../../../../services/markets';
import { useDispatch, useSelector } from 'react-redux';
import { getListings } from '../../../../appRedux/actions';
import { message } from 'antd';



const DynamicPopUpDiv = styled.div`
  ${BodyCSS}
  width: 278px;
  height: 454px;
  position: absolute;
  top: ${({$top}) => `${$top}px`};
  left: ${({$left}) => `${$left}px`};
  z-index: 4;
  padding: 16px;
  display: ${({$display}) => $display};
  flex-direction: column;
  gap: 16px;

`;

const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  text-align: center;
  border: none;
  outline: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  

  position: absolute; 
  top: 4px;
  right: 6px;
`;

const ListItem = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 4px;

  color: #00A4E6;
  font-size: 1rem;
`;

const Circle = styled.div`
  min-width: 5px;
  min-height: 5px;
  background: #B0B0B0;
  border-radius: 100%;
`;

const SubmitDynamicButton = styled.button`
  width: 220px;
  height: 48px;
  border-radius: 4px;
  background: #1BB3CD;
  outline: none;
  border: none;
  color: white;
  text-align: center;
  padding: 8px;
  align-self: center;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s linear;

  margin: 12px 0 0 0;

  &:hover {
    transform: scale(1.05);
  }
`;




const DynamicPopUp = ({isVisible, id, top, left, setPopupParams}) => {

  const tableHeight = document.querySelector('#quibbleTable')?.clientHeight;
  
  const setTop = top > tableHeight/2 ? top - 454 : top + 120;

  const authData = useSelector(({users}) => users.authUserDetails);


  const dispatch = useDispatch();

  const onCloseClick = () => {
    setPopupParams({
      id: '',
      left: 0,
      top: 0,
      isVisible: false,
      updating: false,
    })
  };

  const onSubmitClick = async() => {
    try {
      const dynamicSetResult = await markets.updateListingPricing({id: id, params: {isDynamicPricingEnabled: true} });

      if (!dynamicSetResult.status){
        message.error(`Error on Silent Dynamic Pricing update. Check subscription`)
        setPopupParams({
          id: '',
          left: 0,
          top: 0,
          isVisible: false,
          updating: false,
        });
        return;
      }; 

      dispatch(getListings());

      setPopupParams({
        id: '',
        left: 0,
        top: 0,
        isVisible: false,
        updating: false,
      });

      message.success('Silent Dynamic Pricing update success!')
    } catch(e) {
      console.error(e);
    }
  };


  const pmsURl = {
    Guesty: 'https://quibblerm.com/partners-connections/how-to-connect-with-guesty/',
    OwnerRez: 'https://quibblerm.com/partners-connections/how-to-connect-with-ownerrez/',
    Hostaway: 'https://quibblerm.com/partners-connections/how-to-connect-with-hostaway/',
    Hostfully: 'https://quibblerm.com/partners-connections/how-to-connect-with-hostfully/',
  }



  return (
    <DynamicPopUpDiv $display={isVisible ? 'flex' : 'none'} $top={setTop} $left={left - 20}>
      <CloseButton onClick={onCloseClick}>
        x
      </CloseButton>
      <BodyText margin="16px 0 0 0">
        Checklist for optimal dynamic pricing.
      </BodyText>
      <ListItem href='https://quibblerm.com/how-to-set-the-min-max-price-by-month/' target="_blank" rel="noopener noreferrer">
        <Circle />
        Set Min/Max price by month
      </ListItem>
      <ListItem href='https://quibblerm.com/how-to-use-the-stay-adjustment/' target="_blank" rel="noopener noreferrer">
        <Circle />
        Set Min Stays
      </ListItem>
      <ListItem href='https://quibblerm.com/how-to-set-the-far-out-price/' target="_blank" rel="noopener noreferrer">
        <Circle />
        Set Far Out Price
      </ListItem>
      <ListItem href='https://quibblerm.com/events-pricing/' target="_blank" rel="noopener noreferrer">
        <Circle />
        Set Events
      </ListItem>
      <ListItem href='https://quibblerm.com/competitor-model-check/' target="_blank" rel="noopener noreferrer">
        <Circle />
        Evaluate comps
      </ListItem>
      <ListItem href={pmsURl[authData?.pms]} target="_blank" rel="noopener noreferrer">
        <Circle />
        Have you activated this property in {authData?.pms}?
      </ListItem>
      <SubmitDynamicButton onClick={onSubmitClick}>
        Activate Dynamic Pricing
      </SubmitDynamicButton>
    </DynamicPopUpDiv>
  )
}

export default DynamicPopUp;