import React from 'react';
import styled from 'styled-components';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import { useDispatch, useSelector } from 'react-redux';
import { BodyCSS } from '../../../../components/ReusableComponents/Card-Body/BodyCSS';
import SkLoaderOver from '../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { getPropertyCompetitors, getPropertyDetails, getPropertyModel, getPropertyOverviewMetrics, getPropertyPricing, getPropertyTableMetrics } from '../../../../appRedux/actions';
import { useData } from '../../../../util/DataStore/DataContext';



const WorkingQueuePreviewFilterDiv = styled.div`
  width: 100%;
  max-width: 1776px;
  height: 48px;
`;

const WorkingQueuePreviewFilterFlex = styled.div`
  min-width: 1776px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const LesserSpan = styled.span`
  font-size: 1.15rem;
  margin: 0 4px 0 0;
`;

const BackButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

const NavDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const PrevButton = styled.button`
  ${BodyCSS}
  width: 36px;
  height: 36px;
  text-align: center;
  border: none;
  outline: none; 
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    background: ${({theme}) => theme.disabledGrey};
    transform: unset;
  }
`;

const ThumbnailDiv = styled.div`
  position: relative;
  width: 60px;
  height: 40px;
  border-radius: 4px;

`;

const Thumbmail = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;



const WorkingQueuePreviewFilter = ({setShowPreview, showPreviewIndex, monthYear}) => {

  const { propertyQueue, propertyDetails, isFetchingPropertyDetails } = useSelector(({ property }) => property);

  const dispatch = useDispatch()
  const {state} = useData();


  const onReturnClick = () => {
    setShowPreview({visible: false, id: '', index: 0});
  };

  const isTherePrev = propertyQueue[showPreviewIndex - 1 ];
  const isThereNext = propertyQueue[showPreviewIndex + 1 ];

  const getPropertyData = (propertyId) => {
    const month = monthYear.getMonth() + 1;
    const year =  monthYear.getFullYear();

    dispatch(getPropertyPricing({month, year, propertyId}));
    dispatch(getPropertyDetails({propertyId, params: {month, year}}));
    dispatch(getPropertyOverviewMetrics({propertyId, params: {
      month, year, viewType: state.viewType
    }}));

    dispatch(getPropertyTableMetrics({propertyId, params: {month, year}}));
    dispatch(getPropertyModel({propertyId}));
    dispatch(getPropertyCompetitors({propertyId}));
    setShowPreview({visible: true, id: propertyId,});
  };

  const onPrevClick = () => {
    if (!isTherePrev) return;
    const prevIndex = showPreviewIndex - 1;
    getPropertyData(propertyQueue[prevIndex]?.id)
    setShowPreview((prev) =>({...prev, id: propertyQueue[prevIndex]?.id, index: prevIndex}))
  };

  const onNextClick = () => {
    if (!isThereNext) return;
    const prevIndex = showPreviewIndex + 1;
    getPropertyData(propertyQueue[prevIndex]?.id)
    setShowPreview((prev) =>({...prev, id: propertyQueue[prevIndex]?.id, index: prevIndex}))
  };

  return (
    <WorkingQueuePreviewFilterDiv>
      <WorkingQueuePreviewFilterFlex>
        <BackButton onClick={onReturnClick}>
          <BodyText>
            <LesserSpan>
              {`<`}
            </LesserSpan>
              Back to List
          </BodyText>
        </BackButton>
        <NavDiv>
          <BodyText>
            {`Working Queue (${propertyQueue?.length})`}
          </BodyText>
          <PrevButton disabled={!isTherePrev} onClick={onPrevClick}>
            {`<`}
          </PrevButton>
          <ThumbnailDiv>
            <Thumbmail src={propertyDetails?.tumbnailUrl} alt='thumbnail'/>
            <SkLoaderOver  isLoading={isFetchingPropertyDetails} type='full'/>
          </ThumbnailDiv>
          <PrevButton disabled={!isThereNext} onClick={onNextClick}>
            {`>`}
          </PrevButton>
        </NavDiv>
      </WorkingQueuePreviewFilterFlex>
    </WorkingQueuePreviewFilterDiv>
  )
}

export default WorkingQueuePreviewFilter;